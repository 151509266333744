import React from "react";
import { Dayjs } from "dayjs";
import {
  PickerTimeProps,
  RangePickerTimeProps,
} from "antd/es/date-picker/generatePicker";
import { Omit } from "antd/es/_util/type";

import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import generateCalendar from "antd/es/calendar/generateCalendar";
import "antd/es/calendar/style";

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export default DatePicker;

export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, "picker"> {}
export interface RangeTimePickerProps
  extends Omit<RangePickerTimeProps<Dayjs>, "picker"> {}

const TimePkr = React.forwardRef<any, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

TimePkr.displayName = "TimePicker";

const TimeRangePkr = React.forwardRef<
  any,
  RangeTimePickerProps & { [k: string]: any }
>((props, ref) => {
  return (
    <DatePicker.RangePicker
      {...props}
      picker="time"
      mode={undefined}
      ref={ref}
    />
  );
});

export const TimePicker: typeof TimePkr & {
  RangePicker: typeof TimeRangePkr;
} = TimePkr as any;
TimePicker.RangePicker = TimeRangePkr;

export const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);
