//
import request from "@/libs/fetch";
import Request from "@/libs/fetch";

export const consultPrice = async (params: any) =>
  await request("/consult/price", {
    type: "get",
    params,
  });

export const consultOrder = async (id: string, params?: any) =>
  await request(`/consult/order/${id}`, {
    type: "get",
    params,
  });

export const consultByStripe = async (data: any) =>
  await request(`/consult/by-stripe`, {
    data,
  });
export const cancelIntent = async (intent_id: string) =>
  await request(`/consult/cancel-intent`, {
    data: { intent_id },
  });

export const couponCheck = async (data: any) =>
  await Request(`/coupon/check`, { data });

export const eventMakeOrder = async (data: any) =>
  await Request(`/event/make-order`, { data });

export const checkPurchased = async (data: any) =>
  await Request(`/event/check-participants`, { data });
