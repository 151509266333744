import React from 'react'
import { Switch, Route } from 'react-router-dom'

import NoMatch from '@/pages/error/404'
import DashboardLayout from '@/components/layouts/DashboardLayout'

import Income from '@/pages/dashboard/income'
import IncomeDetail from '@/pages/dashboard/income/IncomeDetail'
import IncomeOrder from '@/pages/dashboard/common/orderNote/order'
import IncomeNote from '@/pages/dashboard/common/orderNote/note'

import Artist from '@/pages/dashboard/artistProfile/Artist'

import Donation from '@/pages/dashboard/donation'
import DonationOverview from '@/pages/dashboard/donation/Overview'

import DonationGeneral from '@/pages/dashboard/donation/GeneralFund'
import DonationGeneralDetail from '@/pages/dashboard/donation/GeneralFundDetail'

import DonationInKind from '@/pages/dashboard/donation/InKind'
import DonationInKindDetail from '@/pages/dashboard/donation/InKindDetail'

import CampaignCreate from '@/pages/dashboard/donation/Create'
import CampaignDetail from '@/pages/dashboard/donation/CampaignDetail'

import Event from '@/pages/dashboard/events'
import EventCreate from '@/pages/dashboard/events/Create'

import Grant from '@/pages/dashboard/grants/grant_list'
import GrantCreate from '@/pages/dashboard/grants/Create'
import GrantPayment from '@/pages/dashboard/grants/Payment'
import GrantRequest from '@/pages/dashboard/grants/grant_request'

import Sponsorship from '@/pages/dashboard/membership'
import MemberOverview from '@/pages/dashboard/membership/Overview'
import MemberChangePayment from '@/pages/dashboard/membership/ChangePayment'
import MemberOverviewDetail from '@/pages/dashboard/membership/OverviewDetail'
import MemberSetting from '@/pages/dashboard/membership/Setting'
import Application from '@/pages/dashboard/membership/Application'
import SponsorshipReview from '@/pages/dashboard/membership/review/SponsorshipReview'
import Summary from '@/pages/dashboard/membership/Summary'
import Agreement from '@/pages/dashboard/membership/Agreement'

import MyFavorite from '@/pages/dashboard/myFavorite/myFavorite'

import AccountSetting from '@/pages/dashboard/accountSetting/accountSetting'

import Document from '@/pages/dashboard/accountSetting/Document'

import MyMsg from '@/pages/dashboard/myMsg/myMsg'
import { ApplicationDetail } from '@/pages/dashboard/grants/application_detail'

import DonationHistory from '@/pages/donor/history/donationHistory'
import { useModel } from 'use-reaction'
import { user } from '@/model/user'
import useWindowSize from 'react-use/lib/useWindowSize'
import { mobileSize } from '@/types/enum'
import bigEmpty from '@/images/big_empty.svg'
import HeaderLayout from '@/components/layouts/HeaderLayout'
import { Transactions } from '@/pages/dashboard/transactions/transactions'
import FinanceContributionOrder from '@/adminApp/pages/admin_finance/contribution/detail'
import { view } from '@/model/view'
import { IncomeArchivedHistory } from '@/pages/dashboard/income/IncomeArchivedHistory'
import { RecurringSetting } from '@/pages/dashboard/recurring/recurring_setting'
import { RecurringChange } from '@/pages/dashboard/recurring/recurring_change'
import { DeactivedPage } from '@/pages/error/deactived'
import { TheField } from '@/components/GoogleTranslate'

const dashboardRoutes = [
  {
    path: '/dashboard/income',
    component: Income,
    exact: true
  },
  {
    path: '/dashboard/income/detail',
    component: IncomeDetail,
    exact: true
  },
  {
    path: '/dashboard/income/detail/:id',
    component: IncomeOrder,
    exact: true
  },
  {
    path: '/dashboard/income/archived',
    component: IncomeArchivedHistory,
    exact: true
  },
  {
    path: '/dashboard/income/detail/:id/note',
    component: IncomeNote,
    exact: true
  },
  {
    path: '/dashboard/artist',
    component: Artist,
    exact: true
  },
  {
    path: '/dashboard/donation/overview',
    component: DonationOverview,
    exact: true
  },
  {
    path: '/dashboard/donation/list',
    component: Donation,
    exact: true
  },
  {
    path: '/dashboard/donation/general',
    component: DonationGeneral,
    exact: true
  },
  {
    path: '/dashboard/donation/:type(general|crowed)/:id(\\d+)',
    component: DonationGeneralDetail,
    exact: true
  },
  {
    path: '/dashboard/donation/inKind',
    component: DonationInKind,
    exact: true
  },
  {
    path: '/dashboard/donation/inKind/:id(\\d+)',
    component: DonationInKindDetail,
    exact: true
  },
  {
    path: '/dashboard/donation/detail/:id(\\d+)',
    component: CampaignDetail,
    exact: true
  },
  {
    path: '/dashboard/donation/create/:id(\\d+)?',
    component: CampaignCreate,
    exact: true
  },
  {
    path: '/dashboard/donation/order/:id(\\d+)',
    component: IncomeOrder,
    exact: true
  },
  {
    path: '/dashboard/donation/note/:id(\\d+)',
    component: IncomeNote,
    exact: true
  },
  {
    path: '/dashboard/artist',
    component: Income,
    exact: true
  },
  {
    path: '/dashboard/event',
    component: Event,
    exact: true
  },
  {
    path: '/dashboard/event/create/:id(\\d+)?',
    component: EventCreate,
    exact: true
  },
  {
    path: '/dashboard/grant',
    component: Grant,
    exact: true
  },
  {
    path: '/dashboard/grant/request/:id(\\d+)?',
    component: GrantRequest,
    exact: true
  },
  {
    path: '/dashboard/grant/create/:id(\\d+)?',
    component: GrantCreate,
    exact: true
  },
  {
    path: '/dashboard/grant/application/:id(\\d+)?',
    component: ApplicationDetail,
    exact: true
  },
  {
    path: '/dashboard/grant/:orderId(\\d+)/:fee(\\d+.?\\d+?)/:name?',
    component: GrantPayment,
    exact: true
  },
  {
    path: '/dashboard/membership/overview',
    component: MemberOverview,
    exact: true
  },
  {
    path: '/dashboard/membership/overview/change/:planId/:priceId',
    component: MemberChangePayment,
    exact: true
  },
  {
    path: '/dashboard/membership/overview/:id(\\d+)',
    component: MemberOverviewDetail,
    exact: true
  },
  {
    path: '/dashboard/membership/fiscal',
    component: Sponsorship,
    exact: true
  },
  {
    path: '/dashboard/membership/payable',
    component: MemberSetting,
    exact: true
  },
  {
    path: '/dashboard/membership/fiscal/application',
    component: Application,
    exact: true
  },
  {
    path: '/dashboard/membership/review/fiscal/SponsorshipReview',
    component: SponsorshipReview,
    exact: true
  },
  {
    path: '/dashboard/membership/fiscal/summary/:renew(renew)?',
    component: Summary,
    exact: true
  },
  {
    path: '/dashboard/membership/fiscal/agreement/:resign(resign)?',
    component: Agreement,
    exact: true
  },
  {
    path: '/dashboard/favorite',
    component: MyFavorite,
    exact: true
  },

  {
    path: '/dashboard/message',
    component: MyMsg,
    exact: true
  },
  {
    path: '/dashboard/message/:to',
    component: MyMsg,
    exact: true
  },
  {
    path: '/dashboard/setting',
    component: AccountSetting,
    exact: true,
    role: ['donor', 'artist']
  },
  {
    path: '/dashboard/document',
    component: Document,
    exact: true
  },
  {
    path: '/dashboard/history',
    component: DonationHistory,
    exact: true,
    role: ['donor', 'artist']
  },
  {
    path: '/dashboard/history/:id(\\d+)',
    component: IncomeOrder,
    exact: true,
    role: ['donor', 'artist']
  },
  {
    path: '/dashboard/history/:id(\\d+)/note',
    component: IncomeNote,
    exact: true,
    role: ['donor', 'artist']
  },
  {
    path: '/dashboard/transactions',
    component: Transactions,
    exact: true,
    role: ['donor', 'artist']
  },
  {
    path: '/dashboard/recurring',
    component: RecurringSetting,
    exact: true,
    role: ['donor']
  },
  {
    path: '/dashboard/recurring-change',
    component: RecurringChange,
    exact: true,
    role: ['donor']
  },
  {
    path: '/dashboard/transaction/:id',
    component: FinanceContributionOrder,
    exact: true,
    role: ['donor', 'artist']
  }
]

const DashboardRouter = () => {
  const {
    store: { roles, info }
  } = useModel(user)

  const {
    store: { isMobile }
  } = useModel(view)
  // return isMobile ? (
  //   <MobileDashBoardEmpty />
  // ) : (
  return (
    <DashboardLayout>
      <Switch>
        {info?.status === 'deactived' ? (
          <Route path="/*" component={DeactivedPage} />
        ) : (
          dashboardRoutes
            .filter((item) => {
              if (roles?.includes('artist')) {
                return true
              } else {
                if (item?.role?.includes('donor')) {
                  return true
                } else {
                  return false
                }
              }
            })
            .map((item) => {
              return <Route key={item.path} {...item} strict />
            })
        )}
        {info?.status !== 'deactived' && <Route path="/dashboard/*" component={NoMatch} />}
      </Switch>
    </DashboardLayout>
  )
  // );
}

const MobileDashBoardEmpty = () => {
  return (
    <div className="mobile-dashboard-empty">
      <HeaderLayout />
      <img src={bigEmpty} alt="empty" />
      <h1>Welcome to <TheField/>!</h1>
      <div className="des">
        Our artist dashboard is not supported on mobile devices yet. Please use a desktop or laptop
        computer to view your dashboard.
      </div>
    </div>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: '/dashboard',
    component: DashboardRouter,
    exact: false
  }
]
