//created by Peggy on 2021/3/24
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Radio,
  Tooltip
} from 'antd'
import { CloudUploadOutlined, InfoCircleFilled } from '@ant-design/icons'

import UploadCrop from '@/components/UploadAndCrop/Cropperjs'
import { useHistory, useParams } from 'react-router-dom'
import { grantApplicationDetail, grantSolicitation } from '@/api/grant-api'

import { ReactComponent as IconCopy } from '@/images/icon_copy.svg'
import icon_donation from '@/images/icons/icon_donation.svg'
import Info from '@/components/Modal/Info'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import DatePicker, { TimePicker } from '@/components/DatePicker'
import { timezoneH } from '@/types/enum'
import { TextEditor } from '@/components/TextEditor/TextEditor'
import { getGrantApplyNotes } from '@/adminApp/apis/grant-api'
import { localStorageGet } from '@/utils/storage'
import { useModel } from 'use-reaction'
import { view } from '@/model/view'
import { businessDays, isRushGrant } from '@/utils/util'
import { TheField } from '@/components/GoogleTranslate'
import qs from "query-string";

const relativeTime = require('dayjs/plugin/relativeTime')
const duration = require('dayjs/plugin/duration')
const utc = require('dayjs/plugin/utc')
const timezones = require('dayjs/plugin/timezone')
const dayjs = require('dayjs')
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezones)

const CreateContainer = styled.div`
  .deadline-h2 {
    margin-bottom: 16px !important;
    & + p {
      height: 22px;
      line-height: 22px;
      margin-top: -12px;
      margin-bottom: 22px;
    }
  }
  .deadline-wrap {
    margin-bottom: 30px;
    & > span {
      font-weight: 600;
      margin-right: 36px;
    }
  }
  .document-wrap {
    display: flex;
    align-items: center;
    &:after {
      display: inline-block;
      width: 12px;
      height: 12px;
      line-height: 12px;
      margin-top: 6px;
      margin-left: 6px;
      content: '*';
      color: #ff4d4f;
    }
  }
  .document-text {
    font-size: 16px;
    p {
      &:last-child {
        padding: 16px;
        border: 1px solid #dddfe4;
        position: relative;
        .copy-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          position: absolute;
          bottom: 8px;
          right: 16px;
          padding: 8px;
          background: #000000;
          border-radius: 3px;
          box-shadow: 6px 4px 14px rgba(0, 0, 0, 0.24);
          cursor: pointer;
        }
      }
    }
  }
  .upload-item-wrap .ant-upload {
    width: 100%;
  }
  .sub-infos {
    background-color: white;
    border: 1px solid #dddfe4;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    padding: 32px;
    width: 100%;
    margin-bottom: 20px;
    .sub-title {
      font-size: 24px;
      color: #000;
    }
  }
`
type Note = {
  id: number
  type: 'link' | 'text'
  content: string
}

const Create = () => {
  const history = useHistory()
  const { mode } = qs.parse(history.location.search);
  const { id } = useParams() as any
  const [form] = Form.useForm()
  //modal
  const [showdays, setShowdays] = useState<undefined | number>()
  const {
    store: { isMobile }
  } = useModel(view)
  const [loading, setLoading] = useState<boolean>(false)
  const [noteTxt, setNoteTxt] = useState<Note>()
  const [data, setData] = useState<{grant_id?:number,status?:string}>({})

  const onFinish = (values: any) => {
    let { type, deadlineType, date, timezone, endTime, documents, ...others } = values,
      days = 0,
      rush,
      deadline

    if (deadlineType) {
      const time = dayjs(date).format('YYYY-MM-DD') + ' ' + dayjs(endTime).format('HH:mm')
      deadline = dayjs.tz(time, timezone)
      rush = !['benefit_event', 'solicitation_letter'].includes(type) && isRushGrant(deadline)
      days = businessDays(deadline)

      if (showdays === undefined && rush) {
        setShowdays(days)
        return false
      }
    }

    let _data = {
      ...others,
      grant_id: data?.grant_id,
      type,
      deadline,
      requireSponsorship: true,
      deadlineStr: [
        dayjs(date).format('YYYY-MM-DD'),
        timezone,
        dayjs(endTime).format('HH:mm')
      ].join(','),
      documents: documents.map((item: any) => item.url),
      rush,
      mode: mode
    }
    setLoading(true)

    grantSolicitation(_data)
      .then((data) => {
        setShowdays(undefined)
        if(data?.orderPaid){
          setShow(true)
          return
        }
        if(!_data.rush){
          setShow(true)
          return
        }
        history.push(`/dashboard/grant/${data.order?.id}/${data?.rushFee}/${_data.name}`)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onFinishFailed = (e: any) => {
    console.log('Failed:', e?.errorFields.map((item: any) => item?.name[0]).join(', '))
    message.error(e?.errorFields[0]?.errors[0])
  }

  useEffect(() => {
    if (id) {
      grantApplicationDetail(id).then((data) => {
        let {
            grant_name: name,
            grant_organization: organization,
            grant_award: award,
            grant_type: type,
            grant_email: email,
            grant_phone: phone,
            grant_link: link,
            grant_deadline,
            grant_deadlineStr,
            notes,
            grant_description: description
          } = data,
          deadlineType = !!grant_deadline ? 1 : 0,
          [date, timezone, endTime] = grant_deadlineStr?.split(',')
        setData(data)
        form.setFieldsValue({
          type,
          name,
          organization,
          link,
          award,
          email,
          phone,
          deadlineType,
          date: (date && dayjs(date)) || '',
          timezone,
          endTime: (endTime && dayjs(date + ' ' + endTime)) || '',
          notes,
          description
        } as any)
      })
      getGrantApplyNotes(id).then((res) => {
        let txt: Note = undefined as any

        const _data: any = res.items.map((item: any, index: number) => {
          if (item.type === 'link') {
            return {
              uid: item.id,
              name: item.content,
              status: 'done',
              url: item.content
            }
          } else if (!txt) {
            txt = item
          }
        })
        txt && setNoteTxt(txt)

        form.setFieldsValue({ documents: _data.filter(Boolean) })
      })
    }
  }, [id])

  //info
  const [show, setShow] = useState(false)
  return (
    <CreateContainer className="event-create-container grant-request-container common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/grant">Grant</Breadcrumb.Item>
        <Breadcrumb.Item>Request Review</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Request Review </h1>
      <p>
        <TheField/> must review all funding solicitations for fiscally sponsored artists. These
        include grants, letters of inquiry, website donation pages, fundraising letters, handbill
        program solicitations, and special event invitations. Please upload your solicitation
        materials using this form, and allow 5 business days for review. Review of grant materials
        submitted within 10 business days of deadline are subject to availability, and a $75 rush
        fee will apply.
      </p>

      <Form
        layout="vertical"
        name="campaign"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="event-form">
          <h2>Request information</h2>
          <Form.Item
            name="type"
            label="Request type"
            rules={[
              {
                required: true,
                message: 'Select a request type'
              }
            ]}
          >
            <Select allowClear placeholder="Select a request type" disabled={data.status === 'incomplete'}>
              <Select.Option value="grant_application_review">
                Grant Proposal/Application
              </Select.Option>
              <Select.Option value="benefit_event">Benefit Event Invitation</Select.Option>
              <Select.Option value="solicitation_letter">
                Solicitation/Fundraising Letter; Handbill Program Solicitations; Website Donation
                Page Language
              </Select.Option>
              <Select.Option value="letter_of_inquiry">
                Letter of Inquiry (1-2 page L.O.I. and no materials needed from fiscal sponsor)
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues?.type !== currentValues?.type}
          >
            {({ getFieldValue }) => {
              if (getFieldValue('type') === 'grant_application_review') {
                return (
                  <>
                    <Form.Item
                      label="grant name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Enter grant name',
                        },
                        {
                          message: 'The name cannot contain special characters!',
                          validator: (_, value) => {
                            const pattern = new RegExp("%");
                            if (pattern.test(value)) {
                              return Promise.reject(); 
                            }
                            return Promise.resolve();
                          },
                        }
                      ]}
                    >
                      <Input maxLength={100} placeholder="Enter grant name" />
                    </Form.Item>
                    <Form.Item
                      label="Grant organization"
                      name="organization"
                      rules={[
                        {
                          whitespace: true,
                          message: 'Enter Grant organization'
                        }
                      ]}
                    >
                      <Input placeholder="Enter Grant organization" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div>
                          GRANT ORGANIZATION CONTACT EMAIL
                          <Tooltip
                            overlayInnerStyle={{ background: '#111', color: '#A2A9AF', width: 600 }}
                            title="Please list the appropriate contact email or phone number at the granting organization who may be contacted with questions regarding this grant. If none are listed or you can't find, please leave blank"
                          >
                            <InfoCircleFilled
                              style={{
                                fontSize: 14,
                                color: '#696969',
                                position: 'absolute',
                                right: -20,
                                top: -2
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="email"
                    >
                      <Input placeholder="Enter Grant organization contact email" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <div>
                          GRANT ORGANIZATION PHONE NUMBER
                          <Tooltip
                            overlayInnerStyle={{ background: '#111', color: '#A2A9AF', width: 600 }}
                            title="Please list the appropriate contact email or phone number at the granting organization who may be contacted with questions regarding this grant. If none are listed or you can't find, please leave blank"
                          >
                            <InfoCircleFilled
                              style={{
                                fontSize: 14,
                                color: '#696969',
                                position: 'absolute',
                                right: -20,
                                top: -2
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="phone"
                    >
                      <Input placeholder="Enter Grant organization phone number" />
                    </Form.Item>

                    <Form.Item
                      label="Grant link"
                      name="link"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Enter grant link'
                        }
                      ]}
                    >
                      <Input placeholder="Enter grant link" />
                    </Form.Item>
                    <Form.Item
                      label="Grant award"
                      name="award"
                      rules={[
                        {
                          whitespace: true,
                          message: 'Enter grant award'
                        }
                      ]}
                    >
                      <Input placeholder="Enter grant award" />
                    </Form.Item>
                    <Divider />
                    <h2 className="deadline-h2">Deadline</h2>
                    <div className="flex start deadline-wrap">
                      <div>Enter the deadline as specified in the grant's guidelines. If the grant has no hard deadline, please enter the date you would like your materials back. Please keep in mind that grant review typically takes 5 - 10 business days and requests submitted within 10 business days are subject to a $75 rush fee.</div>
                      <Form.Item noStyle hidden name="deadlineType" initialValue={1}>
                        <Radio.Group>
                          <Radio value={1}>Hard deadline</Radio>
                          <Radio value={0}>No deadline </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.deadlineType !== currentValues?.deadlineType
                      }
                    >
                      {({ getFieldValue }) => {
                        if (getFieldValue('deadlineType') === 1) {
                          return (
                            <>
                              <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a date'
                                  }
                                ]}
                              >
                                <DatePicker
                                  disabledDate={(current) => {
                                    return current && current < dayjs().endOf('day')
                                  }}
                                  dropdownClassName="date-picker-wrap"
                                  format="YYYY-MM-DD"
                                  placeholder="Select a date"
                                />
                              </Form.Item>
                              <Form.Item
                                name="timezone"
                                label="Select timezone"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a timezone'
                                  }
                                ]}
                              >
                                <Select showSearch placeholder="Select a timezone">
                                  {timezoneH.map((item) => (
                                    <Select.Option key={item.value} value={item.text}>
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                label="time"
                                name="endTime"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select end time'
                                  }
                                ]}
                              >
                                <TimePicker format="h:mmA" placeholder="Select end time" />
                              </Form.Item>
                            </>
                          )
                        }
                      }}
                    </Form.Item>

                    <Divider />
                    <h2>Grant information</h2>
                    <Form.Item
                      className="textarea-wrap"
                      label="Artist notes"
                      name="notes"
                      rules={[
                        {
                          required: true,
                          whitespace: false,
                          message: 'Enter notes'
                        }
                      ]}
                    >
                      <TextEditor placeholder="Tell us about this grant. What specific documents does the funder require from your fiscal sponsor? What type of feedback are you looking for? " />
                    </Form.Item>
                  </>
                )
              } else if (getFieldValue('type') === 'benefit_event') {
                return (
                  <>
                    <Form.Item
                      label="event name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Enter event name'
                        }
                      ]}
                    >
                      <Input maxLength={100} placeholder="Enter event name" />
                    </Form.Item>
                    <Form.Item
                      label="event url"
                      name="link"
                      rules={[
                        {
                          whitespace: true,
                          message: 'Enter event url'
                        }
                      ]}
                    >
                      <Input placeholder="Enter event url" />
                    </Form.Item>
                    <Form.Item
                      label="Short description"
                      name="description"
                      className="textarea-wrap"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Enter short description'
                        }
                      ]}
                    >
                      <Input.TextArea placeholder="Enter short description" />
                    </Form.Item>
                    <h2 className="deadline-h2">Deadline</h2>
                    <p>
                      Review of benefit event materials takes up to 5 business days. If you enter a
                      hard deadline less than 5 business days from today, you will receive a
                      notification if we are unable to complete your request.
                    </p>
                    <div className="flex start deadline-wrap">
                      <span>Deadline type</span>
                      <Form.Item noStyle name="deadlineType" initialValue={1}>
                        <Radio.Group>
                          <Radio value={1}>Hard deadline</Radio>
                          <Radio value={0}>No deadline </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.deadlineType !== currentValues?.deadlineType
                      }
                    >
                      {({ getFieldValue }) => {
                        if (getFieldValue('deadlineType') === 1) {
                          return (
                            <>
                              <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a date'
                                  }
                                ]}
                              >
                                <DatePicker
                                  disabledDate={(current) => {
                                    return current && current < dayjs().endOf('day')
                                  }}
                                  dropdownClassName="date-picker-wrap"
                                  format="YYYY-MM-DD"
                                  placeholder="Select a date"
                                />
                              </Form.Item>
                              <Form.Item
                                name="timezone"
                                label="Select timezone"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a timezone'
                                  }
                                ]}
                              >
                                <Select showSearch placeholder="Select a timezone">
                                  {timezoneH.map((item) => (
                                    <Select.Option key={item.value} value={item.value}>
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                label="time"
                                name="endTime"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select end time'
                                  }
                                ]}
                              >
                                <TimePicker format="h:mm" placeholder="Select end time" />
                              </Form.Item>
                            </>
                          )
                        }
                      }}
                    </Form.Item>

                    <Divider />
                    <h2>Event information</h2>
                    <Form.Item
                      className="textarea-wrap"
                      label="Artist notes"
                      name="notes"
                      rules={[
                        {
                          required: true,
                          whitespace: false,
                          message: 'Enter notes'
                        }
                      ]}
                    >
                      <TextEditor placeholder="Tell us about this benefit. Are you selling ticket levels and/or giving away any rewards? What type of feedback are you looking for?" />
                    </Form.Item>
                  </>
                )
              } else if (getFieldValue('type') === 'solicitation_letter') {
                return (
                  <>
                    <Form.Item
                      label="Campaign Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Enter campaign Name'
                        }
                      ]}
                    >
                      <Input
                        maxLength={100}
                        placeholder="Ex: Spring Fundraising Campaign, End-of-Year Solicitation"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Short description"
                      name="description"
                      className="textarea-wrap"
                      rules={[
                        {
                          whitespace: true,
                          message: 'Enter short description'
                        }
                      ]}
                    >
                      <Input.TextArea placeholder="Enter short description" />
                    </Form.Item>
                    <h2 className="deadline-h2">Deadline</h2>
                    <p>
                      When do you plan to send out this letter? Review of solicitation letters takes
                      up to 5 business days. If you have a hard deadline less than 5 business days
                      from today, you will receive a notification if we are unable to complete your
                      request.
                    </p>
                    <div className="flex start deadline-wrap">
                      <span>Deadline type</span>
                      <Form.Item noStyle name="deadlineType" initialValue={1}>
                        <Radio.Group>
                          <Radio value={1}>Hard deadline</Radio>
                          <Radio value={0}>No deadline </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.deadlineType !== currentValues?.deadlineType
                      }
                    >
                      {({ getFieldValue }) => {
                        if (getFieldValue('deadlineType') === 1) {
                          return (
                            <>
                              <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a date'
                                  }
                                ]}
                              >
                                <DatePicker
                                  disabledDate={(current) => {
                                    return current && current < dayjs().endOf('day')
                                  }}
                                  dropdownClassName="date-picker-wrap"
                                  format="YYYY-MM-DD"
                                  placeholder="Select a date"
                                />
                              </Form.Item>
                              <Form.Item
                                name="timezone"
                                label="Select timezone"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a timezone'
                                  }
                                ]}
                              >
                                <Select showSearch placeholder="Select a timezone">
                                  {timezoneH.map((item) => (
                                    <Select.Option key={item.value} value={item.value}>
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                label="time"
                                name="endTime"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select end time'
                                  }
                                ]}
                              >
                                <TimePicker format="h:mm" placeholder="Select end time" />
                              </Form.Item>
                            </>
                          )
                        }
                      }}
                    </Form.Item>
                  </>
                )
              } else if (getFieldValue('type') === 'letter_of_inquiry') {
                return (
                  <>
                    <Form.Item
                      label="grant name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Enter grant Name'
                        }
                      ]}
                    >
                      <Input maxLength={100} placeholder="Enter grant Name" />
                    </Form.Item>
                    <Form.Item
                      label="Short description"
                      name="description"
                      className="textarea-wrap"
                      rules={[
                        {
                          whitespace: true,
                          message: 'Enter short description'
                        }
                      ]}
                    >
                      <Input.TextArea placeholder="Enter short description" />
                    </Form.Item>
                    <h2 className="deadline-h2">Deadline</h2>
                    <div className="flex start deadline-wrap">
                      <span>Deadline type</span>
                      <Form.Item noStyle name="deadlineType" initialValue={1}>
                        <Radio.Group>
                          <Radio value={1}>Hard deadline</Radio>
                          <Radio value={0}>No deadline </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.deadlineType !== currentValues?.deadlineType
                      }
                    >
                      {({ getFieldValue }) => {
                        if (getFieldValue('deadlineType') === 1) {
                          return (
                            <>
                              <Form.Item
                                label="Date"
                                name="date"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a date'
                                  }
                                ]}
                              >
                                <DatePicker
                                  disabledDate={(current) => {
                                    return current && current < dayjs().endOf('day')
                                  }}
                                  dropdownClassName="date-picker-wrap"
                                  format="YYYY-MM-DD"
                                  placeholder="Select a date"
                                />
                              </Form.Item>
                              <Form.Item
                                name="timezone"
                                label="Select timezone"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select a timezone'
                                  }
                                ]}
                              >
                                <Select showSearch placeholder="Select a timezone">
                                  {timezoneH.map((item) => (
                                    <Select.Option key={item.value} value={item.value}>
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                label="time"
                                name="endTime"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Select end time'
                                  }
                                ]}
                              >
                                <TimePicker format="h:mm" placeholder="Select end time" />
                              </Form.Item>
                            </>
                          )
                        }
                      }}
                    </Form.Item>
                  </>
                )
              } else return null
            }}
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues?.type !== currentValues?.type}
          >
            {({ getFieldValue }) => {
              if (getFieldValue('type')) {
                return (
                  <>
                    <Divider />
                    <h2 className="document-wrap">Documents</h2>
                    {(getFieldValue('type') === 'solicitation_letter' ||
                      getFieldValue('type') === 'benefit_event') && (
                      <div className="document-text">
                        <p>
                          Your{' '}
                          {(getFieldValue('type') === 'letter_of_inquiry' && 'Letter of Inquiry') ||
                            'solicitation'}{' '}
                          text must include this language about <TheField/> below:
                        </p>
                        <p>
                          {localStorageGet('info')?.displayName ||
                            localStorageGet('info')?.firstName +
                              ' ' +
                              localStorageGet('info')?.lastName}{' '}
                          is a sponsored artist with The Performance Zone Inc (dba The Field), a
                          not-for-profit, tax-exempt, 501(c)(3) organization serving the performing
                          arts community. Contributions to <TheField/> earmarked for{' '}
                          {localStorageGet('info')?.displayName ||
                            localStorageGet('info')?.firstName +
                              ' ' +
                              localStorageGet('info')?.lastName}{' '}
                          are tax-deductible to the extent allowed by law. For more information
                          about <span translate="no">The Field</span>, or for our national charities registration, contact: The
                          Field, <span translate="no">228 Park Ave S, Suite 97217, New York, New York 10003</span>, phone: 212-691-6969.
                          A copy of our latest financial report may be obtained from <TheField/> or
                          from the Office of Attorney General, Charities Bureau, 120 Broadway, New
                          York, NY 10271.
                          <CopyToClipboard
                            text={`${
                              localStorageGet('info')?.displayName ||
                              localStorageGet('info')?.firstName +
                                ' ' +
                                localStorageGet('info')?.lastName
                            } is a sponsored artist with The Performance Zone
              Inc (dba The Field), a not-for-profit, tax-exempt, 501(c)(3)
              organization serving the performing arts community. Contributions
              to The Field earmarked for ${
                localStorageGet('info')?.displayName ||
                localStorageGet('info')?.firstName + ' ' + localStorageGet('info')?.lastName
              } are tax-deductible
              to the extent allowed by law. For more information about The
              Field, or for our national charities registration, contact: The
              Field, 228 Park Ave S, Suite 97217, New York, New York 10003, phone:
              212-691-6969. A copy of our latest financial report may be
              obtained from The Field or from the Office of Attorney General,
              Charities Bureau, 120 Broadway, New York, NY 10271.`}
                            onCopy={() => message.success('Copy success')}
                          >
                            <span className="copy-btn">
                              <IconCopy />
                            </span>
                          </CopyToClipboard>
                        </p>
                      </div>
                    )}
                    <Form.Item
                      label=""
                      name="documents"
                      noStyle
                      rules={[
                        {
                          required: true,
                          type: 'array',
                          message: 'documents'
                        }
                      ]}
                    >
                      <UploadCrop
                        folder="grant"
                        listType="text"
                        className="upload-item-wrap"
                        showUploadList
                        accept=".pdf,.doc,.docx,.xlsx,.csv,.png,.jpeg,.jpg"
                        maxCount={15}
                        crop={false}
                      >
                        <div className="children-upload">
                          <p className="ant-upload-drag-icon">
                            <CloudUploadOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Upload 1 file at a time. We support .pdf,.xls, .xlsx, .doc, .docx, .png,
                            .jpeg. File size should be under 100MB
                          </p>
                        </div>
                      </UploadCrop>
                    </Form.Item>
                  </>
                )
              }
            }}
          </Form.Item>
        </div>
          <p className="flex end btn">
            {!id && (<Button
              loading={loading}
              type="primary"
              onClick={() => form.submit()}
              style={isMobile ? { margin: 0, flex: 1 } : {}}
            >
              Submit
            </Button>)}
            {data.status === 'incomplete' && (<Button
              loading={loading}
              type="primary"
              onClick={() => form.submit()}
              style={isMobile ? { margin: 0, flex: 1 } : {}}
            >
              Update Request
            </Button>)}
          </p>
        
      </Form>
      <div hidden={!id} className="sub-infos">
        <h3 className="sub-title">Admin notes</h3>
        <div className="artist-notes">{noteTxt?.content}</div>
      </div>
      <Modal
        title={`This grant is due in ${showdays} Business Days!`}
        wrapClassName="grant-confirm-modal"
        visible={!!showdays}
        okText={isMobile ? 'rush request' : 'request rush application'}
        width={570}
        onCancel={() => {
          setShowdays(undefined)
        }}
        onOk={() => {
          form.submit()
          setShowdays(0)
        }}
      >
        If you’re applying for a grant using <TheField/> as your fiscal sponsor, you must submit your
        application materials to us at least 10 business days before the deadline. This gives us
        time to review your application, provide feedback if necessary, and gather the documents
        required by the granting organization.
        <br />
        <br />
        Late application reviews are subject to availability. A $75 rush fee will apply. We will
        only charge your credit card if your rush application request is approved.
      </Modal>
      <Info
        className="event-info-modal"
        visible={show}
        width={660}
        footer={[
          <Button onClick={() => history.push('/dashboard/message')}>contact us</Button>,
          <Button onClick={() => history.push('/dashboard/grant')} type="primary">
            Got it
          </Button>
        ]}
        img={icon_donation}
      >
        <h1>
          Your {form.getFieldValue('type') === 'grant_application_review' ? 'grant' : ''} review
          request has been received!
        </h1>
        <p>
          {form.getFieldValue('type') !== 'grant_application_review'
            ? 'We have received your request. Once the review is completed, you will receive an email with next steps.'
            : 'We have received your application and will confirm your eligibility.Once the review is completed, you will receive an email with next steps.'}
        </p>
      </Info>
    </CreateContainer>
  )
}

export default Create
