//created by Peggy on 2021/2/19
import React, { useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Divider,
  TimePicker,
  message,
  Tooltip,
} from "antd";
import DatePicker from "@/components/DatePicker";

import {
  CloudUploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";

import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import { putArtist, saveProject } from "@/api/artist-api";
import { eventEditSave } from "@/api/event-api";
import { crowPut, crowSave } from "@/api/donation-api";

import instagram from "@/images/instagram.svg";

import { router_insta_auth } from "@/types/enum";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import SelectAndInput from "@/components/Select/SelectAndInput";
import YoutubeInput from "@/components/Input/YoutubeInput";
import { useModel } from "use-reaction";
import { user, saveUser } from "@/model/user";
import { TextEditor } from "@/components/TextEditor/TextEditor";
import { intersection } from "lodash";
import { TheField } from "../GoogleTranslate";

interface Props {
  data?: any;
  show?: string | boolean;
  insName?: string;
  insDate?: string;
  onOk?: () => void;
  onCancel?: () => void;
  removeInsta: () => void;
}

const EditForm = ({
  data = "",
  show = false,
  onOk = () => { },
  onCancel = () => { },
  insName = "",
  insDate = "",
  removeInsta,
}: Props) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const uploadRef = useRef<any>();

  const { doAction } = useModel(user);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // form.resetFields();
        let {
          allowMessage,
          allowShare,
          setting = [],
          avatarList = [],
          avatar,
          photosList = [],
          photos,

          crowdId,
          name,
          description,
          goalAmount,
          donateDate,

          events,
          project_id,
          project_video,
          project_feature_photos,
          project_photos,
          project_synopsis,
          project_title,
          project_description,
          facebook,
          tiktok,
          youtube,
          vimeo,
          linkedin,
          primaryTag,
          ...others
        } = values;
        if (show === "info") {
          allowMessage = setting.includes("message");
          allowShare = setting.includes("share");
          avatar = avatarList?.map((item: any) => item.url).join(",");
        }
        if (show === "about") {
          photos = photosList?.map((item: any) => item.url);
        }

        if (show === "instagram") {
          if (facebook) {
            facebook = facebook.startsWith("http")
              ? facebook
              : "https://" + facebook;
          }
          if (tiktok) {
            tiktok = tiktok.startsWith("http") ? tiktok : "https://" + tiktok;
          }
          if (youtube) {
            youtube = youtube.startsWith("http")
              ? youtube
              : "https://" + youtube;
          }
          if (linkedin) {
            linkedin = linkedin.startsWith("http")
              ? linkedin
              : "https://" + linkedin;
          }
          if (vimeo) {
            vimeo = vimeo.startsWith("http")
              ? vimeo
              : "https://" + vimeo;
          }
        }

        let _data = {
          ...others,
          biographyFormat: data?.biographyFormat,
          avatar,
          photos,
          allowMessage,
          allowShare,
          facebook,
          tiktok,
          youtube,
          vimeo,
          linkedin,
          primaryTag: Array.isArray(primaryTag) ? primaryTag[0] : primaryTag
        };

        if (show === "donate") {
          const [startDate, endDate] = donateDate || [null, null];
          if (data?.crowdfunding?.id) {
            crowPut(data.crowdfunding.id, {
              name,
              description,
              goalAmount,
              donateDate,
              startDate,
              endDate,
              status: "draft",
            }).then(() => {
              form.resetFields();
              onOk();
            });
          } else {
            crowSave({
              name,
              description,
              goalAmount,
              donateDate,
              startDate,
              endDate,
              status: "draft",
            }).then((data) => {
              form.resetFields();
              onOk();
            });
          }

          return false;
        }

        if (show?.toString()?.indexOf("project") > -1) {
          saveProject({
            id: project_id,
            project_video,
            project_feature_photos: project_feature_photos?.map(
              (item: any) => item.url
            ),
            project_photos: project_photos?.map((item: any) => item.url),
            project_synopsis,
            project_title,
            project_description,
          }).then(() => {
            form.resetFields();
            onOk();
          });
          return;
        }

        if (show === "event") {
          console.log(events);
          const _events = events?.map(
            ({ photos, date, timeDur, time, ...others }: any) => {
              let [startDate, endDate] = date || [null, null],
                [startTime, endTime] = timeDur || [null, null];
              photos = photos?.map((item: any) => item.url);
              return {
                ...others,
                photos,
                time: {
                  ...time,
                  startDate,
                  endDate,
                  startTime,
                  endTime,
                },
              };
            }
          );
          eventEditSave({
            event: _events,
          }).then(() => {
            form.resetFields();
            onOk();
          });
          return;
        }

        putArtist(_data).then(() => {
          if (show === "info") {
            doAction(saveUser, {
              avatar,
            });
          }
          form.resetFields();
          onOk();
        });
      })
      .catch((e) => {
        console.log("Validate Failed:", e);
        message.error(e.errorFields[0].errors[0]);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (data && show) {
      const {
        crowdfunding: {
          startDate = "",
          endDate = "",
          id: crowdId,
          ...otherCrowd
        },
        videos,
        events,
        avatar,
        projects,
        biography,
        biographyFormat,
        primaryTag,
        ...others
      } = JSON.parse(JSON.stringify(data)) as any,
        _events = events?.map((item: any) => {
          item.date = [dayjs(item.time.startDate), dayjs(item.time.endDate)];
          item.timeDur = [dayjs(item.time.startTime), dayjs(item.time.endTime)];
          item.photos = item.photos?.map((item: any, index: number) => ({
            uid: index,
            name: item,
            status: "done",
            url: item,
          }));
          return item;
        }),
        _videos = videos?.length
          ? videos
          : [
            {
              title: "",
              description: "",
            },
          ],
        avatarList =
          (avatar &&
            avatar?.split(",")?.map((item: any, index: number) => ({
              uid: index,
              name: item,
              status: "done",
              url: item,
            }))) ||
          [];
      let _projects = {
        project_title: "",
        project_photos: [],
      };
      if (projects.length && show?.toString()?.indexOf("project") > -1) {
        if (show?.toString()?.split("-")?.length > 1) {
          let {
            id,
            project_photos,
            project_feature_photos,
            ...others
          }: any = projects[show?.toString()?.split("-")[1]];
          _projects = {
            ...others,
            project_id: id,
            project_photos: project_photos?.map((item: any, index: number) => ({
              uid: index,
              name: item,
              status: "done",
              url: item,
            })),
            project_feature_photos: project_feature_photos?.map(
              (item: any, index: number) => ({
                uid: index,
                name: item,
                status: "done",
                url: item,
              })
            ),
          };
        }
      }
      form &&
        form.setFieldsValue({
          ...otherCrowd,
          ...others,
          crowdId,
          primaryTag: Array.isArray(primaryTag) ? primaryTag : [primaryTag],
          avatarList,
          videos: _videos,
          events: _events,
          ..._projects,
          biography:
            biographyFormat === "plain"
              ? biography?.replace(/(<([^>]+)>)/gi, "")
              : biography || undefined,
          donateDate: [
            startDate ? dayjs(startDate) : "",
            endDate ? dayjs(endDate) : "",
          ],
        });
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <Modal
      className="edit-form-modal common-modal"
      title={
        show === "donate"
          ? "Edit crowdfunding campaign"
          : show === "about"
            ? "Edit about me"
            : show === "media"
              ? "Edit featured videos"
              : show?.toString()?.indexOf("project") > -1
                ? "Edit project"
                : show === "event"
                  ? "Edit event"
                  : show === "instagram"
                    ? "Edit social media"
                    : "Edit artist information"
      }
      width={show?.toString()?.indexOf("project") > -1 ? 920 : 520}
      visible={!!show}
      onOk={handleSubmit}
      okText="save"
      cancelText="discard changes"
      onCancel={handleCancel}
      forceRender
      centered
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        {show === "info" && (
          <div className={`info-wrap ${show === "info" ? "" : "hidden"}`}>
            <h2 onClick={() => console.log(uploadRef)}>Display information</h2>
            <Form.Item noStyle name="avatarList">
              <UploadCrop
                ref={uploadRef}
                listType="picture-card"
                className="upload-item-wrap"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                }}
                maxCount={1}
              />
            </Form.Item>

            <Form.Item
              label="Display name"
              name="displayName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter display name",
                },
              ]}
            >
              <Input
                autoComplete="off"
                maxLength={100}
                placeholder="Enter display name"
              />
            </Form.Item>
            {/*<Form.Item*/}
            {/*  label="First Name"*/}
            {/*  name="firstName"*/}
            {/*  rules={[*/}
            {/*    {*/}
            {/*      required: true,*/}
            {/*      whitespace: true,*/}
            {/*      message: "Enter first Name",*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*>*/}
            {/*  <Input*/}
            {/*    autoComplete="off"*/}
            {/*    maxLength={30}*/}
            {/*    placeholder="Enter first name"*/}
            {/*  />*/}
            {/*</Form.Item>*/}
            {/*<Form.Item*/}
            {/*  label="Last Name"*/}
            {/*  name="lastName"*/}
            {/*  rules={[*/}
            {/*    {*/}
            {/*      required: true,*/}
            {/*      whitespace: true,*/}
            {/*      message: "Enter last name",*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*>*/}
            {/*  <Input maxLength={30} placeholder="Enter last name" />*/}
            {/*</Form.Item>*/}
            <Form.Item
              label="Primary Discipline"
              name="primaryTag"
              dependencies={['tags']}
              rules={[
                {
                  whitespace: true,
                  message: "Primary Discipline",
                  validator: (rule, value, cb) => {
                    const otherTags = form.getFieldValue('tags')
                    if (intersection(otherTags, value).length) {
                      const error = 'Your primary discipline can not be the same as your other disciplines'
                      message.error(error)
                      return Promise.reject(error)
                    }
                    return Promise.resolve()
                  }
                },
              ]}
            >
              <SelectAndInput mode="single" />
            </Form.Item>
            <Form.Item
              name="tags"
              label={
                <div>
                  Other Disciplines
                  <Tooltip
                    className="tooltip-field-item"
                    title="Input your discipline here. If you select other, please be sure to hit enter on your keyboard to save your option. "
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </div>
              }
              rules={[
                {
                  required: true,
                  type: "array",
                  message: "Please select your disciplines",
                  validator: (rule, value, cb) => {
                    const primaryTag = form.getFieldValue('primaryTag')
                    if (intersection(primaryTag, value).length) {
                      const error = 'Your primary discipline can not be the same as your other disciplines'
                      return Promise.reject(error)
                    }
                    return Promise.resolve()
                  }
                },
              ]}
            >
              <SelectAndInput />
            </Form.Item>
            {/*<h2 className="item-title">Sharing settings</h2>*/}
            {/*<Form.Item*/}
            {/*  name="setting"*/}
            {/*  label=""*/}
            {/*  noStyle*/}
            {/*  rules={[*/}
            {/*    {*/}
            {/*      required: true,*/}
            {/*      type: "array",*/}
            {/*      message: "Please select your country!",*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*>*/}
            {/*  <Checkbox.Group>*/}
            {/*    <Checkbox value="message">Allow users to message you</Checkbox>*/}
            {/*    <Checkbox value="share">*/}
            {/*      Allow users to share your profile*/}
            {/*    </Checkbox>*/}
            {/*  </Checkbox.Group>*/}
            {/*</Form.Item>*/}
          </div>
        )}
        {show === "donate" && (
          <div className={`donate-wrap ${show === "donate" ? "" : "hidden"}`}>
            <h2>Donation information</h2>
            <Form.Item
              label="campaign name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter campaign name",
                },
              ]}
            >
              <Input maxLength={50} placeholder="Enter campaign name" />
            </Form.Item>
            <Form.Item
              className="textarea-wrap"
              label="campaign description"
              name="description"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter campaign description",
                },
              ]}
            >
              <Input.TextArea
                autoSize={true}
                maxLength={300}
                placeholder="Enter campaign description"
              />
            </Form.Item>
            <Form.Item
              label="Donation goal"
              name="goalAmount"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter Donation goal",
                },
              ]}
            >
              <Input maxLength={30} placeholder="Enter Donation goal" />
            </Form.Item>

            <h2 className="flex donate-title">
              Donation duration
              {/*<span>*/}
              {/*  Enable duration*/}
              {/*  <Switch*/}
              {/*    defaultChecked={switchValue}*/}
              {/*    onChange={(e) => setSwitch(e)}*/}
              {/*  />*/}
              {/*</span>*/}
            </h2>
            <Form.Item
              label="Donation Date"
              name="donateDate"
              rules={[
                {
                  type: "array",
                  message: "Enter Donation Date",
                },
              ]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
          </div>
        )}
        {show === "about" && (
          <div className={`about-wrap ${show === "about" ? "" : "hidden"}`}>
            <h2>Biography</h2>
            <Form.Item
              className="textarea-wrap"
              name="biography"
              label="About me"
              rules={[
                {
                  message: "please enter at least 600 characters ",
                },
              ]}
            >
              {data?.biographyFormat === "plain" ? (
                <Input.TextArea placeholder="Enter biography" />
              ) : (
                <TextEditor placeholder="Enter biography" />
              )}
            </Form.Item>
            {data?.biographyFormat === "plain" && (
              <>
                <h2>Featured photos</h2>
                <Form.Item name="photosList" noStyle>
                  <UploadCrop
                    folder="user_about"
                    scale={724 / 724}
                    listType="picture"
                    className="upload-item-wrap"
                    maxCount={1}
                    showUploadList
                  >
                    <div className="children-upload">
                      <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Please upload 1 file at a time. Strictly prohibit from
                        uploading company data or other band files
                      </p>
                    </div>
                  </UploadCrop>
                </Form.Item>
              </>
            )}
          </div>
        )}
        {show === "media" && (
          <div className={`media-wrap ${show === "media" ? "" : "hidden"}`}>
            <Form.List name="videos">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.fieldKey}>
                      <h2 className="flex">
                        Featured video {index + 1}
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </h2>
                      <Form.Item
                        label="Video title"
                        name={[field.name, "title"]}
                        fieldKey={[field.fieldKey, "title"]}
                        rules={[{ required: true, message: "Missing title" }]}
                      >
                        <Input
                          autoComplete="off"
                          maxLength={50}
                          placeholder="Video title"
                        />
                      </Form.Item>
                      <Form.Item
                        className="textarea-wrap"
                        label="Video description"
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        rules={[
                          { required: true, message: "Missing description" },
                        ]}
                      >
                        <Input.TextArea
                          autoComplete="off"
                          maxLength={300}
                          placeholder="Video description"
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "url"]}
                        fieldKey={[field.fieldKey, "url"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Youtube or Vimeo url",
                          },
                          {
                            pattern: /((?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11}))|(vimeo\.com\/[\d]{8})/g,
                            message: "Please double-check your video link",
                          },
                        ]}
                        className="youtube-field-item field-form-item-required"
                      >
                        <YoutubeInput
                          placeholder="Enter youtube or Vimeo url"
                          label="Youtube or Vimeo url"
                        />
                      </Form.Item>
                    </div>
                  ))}
                  <Form.Item noStyle>
                    <Button
                      type="text"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add another video url
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        )}
        {show?.toString()?.indexOf("project") > -1 && (
          <div
            className={`project-wrap ${show?.toString()?.indexOf("project") > -1 ? "" : "hidden"
              }`}
          >
            <h2 className="flex">Project information</h2>
            <Form.Item name="project_id" noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item
              label="Project title"
              name="project_title"
              rules={[{ required: true, message: "Missing title" }]}
            >
              <Input placeholder="Project title" />
            </Form.Item>
            <Form.Item
              className="textarea-wrap"
              label="Project synopsis"
              name="project_synopsis"
              rules={[{ required: true, message: "Missing synopsis" }]}
            >
              <Input.TextArea placeholder="A short description of your project - this will appear above your featured image." />
            </Form.Item>
            <h4>Featured Image</h4>
            <Form.Item
              label=""
              noStyle
              name="project_feature_photos"
              rules={[{ required: true, message: "Missing featured image" }]}
            >
              <UploadCrop
                folder="user_project"
                scale={117 / 67}
                listType="picture"
                className="upload-item-wrap"
                showUploadList
                maxCount={1}
              >
                <div className="children-upload">
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Upload 1 featured image to represent your project
                  </p>
                </div>
              </UploadCrop>
            </Form.Item>
            <Form.Item
              className="textarea-wrap"
              label="Project description"
              name="project_description"
              rules={[{ required: true, message: "Missing description" }]}
            >
              <TextEditor placeholder="A detailed summary of your project - this will appear below your featured image." />
            </Form.Item>

            <h4>Project Images</h4>
            <Form.Item label="" noStyle name="project_photos">
              <UploadCrop
                folder="user_project"
                scale={506 / 270}
                listType="picture"
                className="upload-item-wrap"
                showUploadList
                maxCount={15}
              >
                <div className="children-upload">
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Add additional images</p>
                </div>
              </UploadCrop>
            </Form.Item>
            <h4>Project video</h4>
            <Form.Item
              name="project_video"
              rules={[
                {
                  pattern: /((?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11}))|(vimeo\.com\/[\d]{8})/g,
                  message: "Please double-check your video link",
                },
              ]}
              className="youtube-field-item"
            >
              <YoutubeInput
                placeholder="Enter youtube url"
                label="Youtube url"
              />
            </Form.Item>
          </div>
        )}
        {show === "event" && (
          <div className={`event-wrap ${show === "event" ? "" : "hidden"}`}>
            <Form.List name="events">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={"event" + index}>
                      <h2 className="flex">Event information {index + 1}</h2>
                      <Form.Item
                        label="event title"
                        name={[field.name, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        rules={[{ required: true, message: "Missing title" }]}
                      >
                        <Input placeholder="Event title" />
                      </Form.Item>
                      <Form.Item
                        className="textarea-wrap"
                        label="event description"
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        rules={[
                          { required: true, message: "Missing description" },
                        ]}
                      >
                        <Input.TextArea placeholder="Event description" />
                      </Form.Item>
                      <Form.Item
                        label="Date"
                        name={[field.name, "date"]}
                        fieldKey={[field.fieldKey, "date"]}
                        rules={[{ required: true, message: "Missing date" }]}
                      >
                        <DatePicker.RangePicker
                          dropdownClassName="date-picker-wrap"
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item
                        label="time"
                        name={[field.name, "timeDur"]}
                        fieldKey={[field.fieldKey, "timeDur"]}
                        rules={[{ required: true, message: "Missing time" }]}
                      >
                        <TimePicker.RangePicker
                          popupClassName="date-picker-wrap"
                          use12Hours
                          format="h:mm A"
                        />
                      </Form.Item>
                      <Form.Item
                        label=""
                        noStyle
                        name={[field.name, "photos"]}
                        fieldKey={[field.fieldKey, "photos"]}
                      >
                        <UploadCrop
                          folder="event"
                          listType="picture"
                          className="upload-item-wrap"
                          showUploadList
                          maxCount={2}
                        >
                          <div className="children-upload">
                            <p className="ant-upload-drag-icon">
                              <CloudUploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Please upload 1 file at a time. Strictly prohibit
                              from uploading company data or other band files
                            </p>
                          </div>
                        </UploadCrop>
                      </Form.Item>
                      {/*<Form.Item*/}
                      {/*  label="Video url"*/}
                      {/*  name={[field.name, "url"]}*/}
                      {/*  fieldKey={[field.fieldKey, "url"]}*/}
                      {/*  rules={[{ required: true, message: "Missing url" }]}*/}
                      {/*>*/}
                      {/*  <Input placeholder="Video url" />*/}
                      {/*</Form.Item>*/}
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </div>
        )}
        {show === "instagram" && (
          <div
            className={`instagram-wrap ${show === "instagram" ? "" : "hidden"}`}
          >
            <h2>Instagram</h2>
            <h4>
              In order to display your instagram on your <TheField/> profile,
              please click on the button below. It will redirect you to the
              instagram platform. If you’re not familiar with this flow, please
              view the instruction here
            </h4>
            <Button
              type="primary"
              onClick={(e) => {
                const from = `${window.location.pathname}`;
                history &&
                  history.push({
                    pathname: router_insta_auth,
                    search: `from=${from}`,
                  });
              }}
            >
              Connect instagram Profile
            </Button>
            <div className="flex instagram-item">
              <div className="flex start">
                <img src={instagram} alt="" />
                <p className="flex column align-start">
                  <h3>@{insName}</h3>
                  <i>Added on {insDate}</i>
                </p>
              </div>
              <Button onClick={removeInsta} type="primary">
                Remove
              </Button>
            </div>
            <Divider />
            <h2>Other social media</h2>
            <Form.Item
              label="Facebook URL"
              name="facebook"
              rules={[
                {
                  whitespace: true,
                  message: "Enter Facebook URL",
                },
                {
                  pattern: /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                  message: "Enter valid url",
                },
              ]}
            >
              <Input placeholder="Enter Facebook URL" />
            </Form.Item>
            <Form.Item
              label="Twitter URL"
              name="twitter"
              rules={[
                {
                  whitespace: true,
                  message: "Enter Twitter URL",
                },
                {
                  pattern: /((https:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                  message: "Enter valid url",
                },
              ]}
            >
              <Input placeholder="Enter Twitter URL" />
            </Form.Item>
            <Form.Item
              label="Tiktok URL"
              name="tiktok"
              rules={[
                {
                  whitespace: true,
                  message: "Enter Tiktok URL",
                },
                {
                  pattern: /((https:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                  message: "Enter valid url",
                },
              ]}
            >
              <Input placeholder="Enter Tiktok URL" />
            </Form.Item>
            <Form.Item
              label="Youtube URL"
              name="youtube"
              rules={[
                {
                  whitespace: true,
                  message: "Enter Youtube URL",
                },
                {
                  pattern: /(http(s)?:\/\/)?\w*\.youtube\..*/,
                  message: "Enter valid url",
                },
              ]}
            >
              <Input placeholder="Enter Youtube URL" />
            </Form.Item>
            <Form.Item
              label="Vimeo URL"
              name="vimeo"
              rules={[
                {
                  whitespace: true,
                  message: "Enter Veni URL",
                },
                {
                  pattern: /(http(s)?:\/\/)?[\w*\.]?vimeo\..*/,
                  message: "Enter valid url",
                },
              ]}
            >
              <Input placeholder="Enter Vimeo URL" />
            </Form.Item>
            <Form.Item
              label="LinkedIn URL"
              name="linkedin"
              rules={[
                {
                  whitespace: true,
                  message: "Enter LinkedIn URL",
                },
                {
                  pattern: /((https:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                  message: "Enter valid url",
                },
              ]}
            >
              <Input placeholder="Enter LinkedIn URL" />
            </Form.Item>
            <Form.Item
              label="Website"
              name="website"
              rules={[
                {
                  whitespace: true,
                  message: "Enter website URL",
                  required: false
                },
                {
                  pattern: /((https:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                  message: "Enter valid url",
                },
              ]}
            >
              <Input placeholder="Enter website URL" />
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default EditForm;
