//created by Peggy on 2021/3/30
import React, { useEffect, useState } from "react";

import {
  Select,
  Button,
  Dropdown,
  Menu,
  Form,
  Modal,
  message,
  Input,
  Table,
} from "antd";
import DatePicker from "@/components/DatePicker";

import { CloudUploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import styled from "styled-components";
import "@/styles/dashboard/setting.less";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";

import {
  getDocument,
  saveDocument,
  saveLetter,
  downloadDocument,
} from "@/api/document-api";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import useConfirm from "@/components/Modal/Confirm";
import { localStorageGet } from "@/utils/storage";
import { useHistory } from "react-router-dom";
import { getSponsor } from "@/api/merbership-api";
import MerbershipModal from "@/components/Modal/Info";
import {
  getPopupButtonByMembershipStatus,
  getPopupDescByMembershipStatus,
  getPopupTitleByMembershipStatus,
} from "@/utils/common";
import { TheField } from "@/components/GoogleTranslate";

const DocumentContainer = styled.div``;

// docTypes are same with defined in BE, do not modify!!
const docTypeOfGrants = [
  "Benefit Event",
  "Letter of Inquiry",
  "New Grant Request",
  "Grant Application",
  "Solicitation Letter",
];
const docTypeOther = "Other";
const documentOptions = [
  "Sponsorship Agreement",
  // "Tax/Invoices",
  "Affiliation letters",
];

const Document = () => {
  //table
  const columns: any[] = [
    {
      title: "document name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 150,
      className: "doc-name",
      ellipsis: true,
      render: (name: string) => decodeURI(name),
    },
    {
      title: "document type",
      dataIndex: "type",
      key: "type",
      width: 150,
      ellipsis: true,
    },

    {
      title: "date added",
      width: 130,
      dataIndex: "createDate",
      render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: "",
      width: 80,
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["hover"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() => {
                    if (row.type === "Sponsorship Agreement") {
                      showDownload(row.id);
                    } else {
                      window.open(row.url);
                    }
                  }}
                >
                  Download
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    pageSize: 20,
    keyword: "",
  });
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);
  const {
    store: { isMobile },
  } = useModel(view);
  const getList = () => {
    const { pageSize: limit, ...others } = params;
    let _data = {
      ...others,
      limit,
    };
    getDocument(_data).then((data) => {
      setTableData(data.items);
      setTotal(data.total);
    });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  //letter
  const [letterForm] = Form.useForm();
  const [letter, setLetter] = useState(false);
  const userInfo = localStorageGet("info");
  const canGoRequest = userInfo.sponsorshipValid;
  const history = useHistory();
  const onSetLetter = () => {
    canGoRequest
      ? setLetter(true)
      : membershipStatus === "completed"
      ? history.push("/dashboard/membership/overview")
      : setMembershipShow(true);
  };
  const handleSubmitLetter = () => {
    setLoading(true);
    letterForm
      .validateFields()
      .then((values) => {
        const { customSalutation, ...others } = values;
        saveLetter({
          lines: Object.values(others).filter((item) => item),
          customSalutation,
        })
          .then((data) => {
            message.success("successfully.");
            window.open(data.url);
            onCancelLetter();
            getList();
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCancelLetter = () => {
    letterForm.resetFields();
    setLoading(false);
    setLetter(false);
  };

  //Modal
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        const { document, ...others } = values;
        saveDocument({
          ...others,
          url: document.map((item: any) => item.url).join(","),
        })
          .then((data) => {
            message.success("document added successfully.");
            onCancel();
            getList();
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onCancel = () => {
    form.resetFields();
    setLoading(false);
    setShow(false);
  };

  //download need password
  const [password, setPassword] = useState("");
  const [wait, setWait] = useState(false);
  const { setShow: showDownload, component } = useConfirm(
    <div className="flex column start align-start">
      <p>
        Please input your log-in password in order to download this document
      </p>
      <Input.Password
        size="large"
        autoComplete="off"
        value={password}
        onChange={(e) => setPassword(e?.target.value)}
        placeholder="Please input your password"
      />
    </div>,
    {
      title: "Download contract",
      className: "suspend-confirm-modal",
      okText: "Download",
      width: 420,
      onOk: (id?: any) => {
        if (!password) {
          message.error("please input your password");
          return false;
        }
        if (wait) {
          return false;
        } else {
          setWait(true);
        }
        downloadDocument({
          id,
          password,
        })
          .then((data) => {
            showDownload(false);
            setWait(false);
            setPassword("");
            window.open(data.url);
          })
          .catch((e) => {
            setPassword("");
            setWait(false);
          });
      },
    }
  );

  // Membership Model
  const [membershipStatus, setMembershipStatus] = useState("");
  const [membershipShow, setMembershipShow] = useState(false);
  useEffect(() => {
    getSponsor({}).then((data: any) => {
      setMembershipStatus(data?.status);
    });
  }, []);

  return (
    <DocumentContainer className="common-container document-container">
      <h1>Documents</h1>
      <div className={`search-wrap ${isMobile ? "" : "flex"}`}>
        <div className="flex">
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              onChange={(e) => {
                const [startDate, endDate] = e || [null, null];
                setParams({
                  ...params,
                  startDate,
                  endDate,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>document type</span>
            <Select
              allowClear
              bordered={false}
              placeholder="Please select "
              mode="multiple"
              onChange={(e) => {
                setParams({
                  ...params,
                  type: e,
                });
              }}
            >
              {[...documentOptions, ...docTypeOfGrants, docTypeOther].map(
                (item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                )
              )}
            </Select>
          </div>
        </div>
        <div className="btns">
          <Button onClick={onSetLetter}>
            {isMobile ? "affiliation letter" : "Get affiliation letter"}
          </Button>
          <Button type="primary" onClick={() => setShow(true)}>
            upload document
          </Button>
        </div>
      </div>
      <Table
        className="table-item table-container"
        title={() => (
          <div className="flex">
            <span>Documents</span>
            <Input.Search
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total,
          current: params.page,
          pageSize: params.pageSize,
          onChange: (page: number) => {
            setParams({
              ...params,
              page,
            });
          },
        }}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
      />
      {component}
      <Modal
        className="common-modal document-modal"
        title="Get Affiliation Letter"
        visible={letter}
        onOk={handleSubmitLetter}
        okButtonProps={{
          loading: loading,
        }}
        okText="save"
        cancelText="cancel"
        onCancel={onCancelLetter}
        forceRender
        centered
      >
        <p>
          Provide details for how this letter should be addressed, including the
          recipient's name, company, and address if available. Please note that
          Members of <TheField/> are not exempt from paying sales tax. View{" "}
          <a
            href="https://s3.us-west-2.amazonaws.com/prod-static.thefield.org/document/sample_affiliation_letter.pdf"
            target="_blank"
            rel="noreferrer"
          >
            sample affiliation letter
          </a>
          .
        </p>
        <h3>Receiver Contact Information</h3>
        <Form form={letterForm} layout="vertical" name="form_inking">
          <Form.Item
            label="Line 1"
            name="line_1"
            rules={[
              {
                whitespace: true,
                message: "Input text",
              },
            ]}
          >
            <Input placeholder="Input text" />
          </Form.Item>
          <Form.Item
            label="Line 2"
            name="line_2"
            rules={[
              {
                whitespace: true,
                message: "Input text",
              },
            ]}
          >
            <Input placeholder="Input text" />
          </Form.Item>
          <Form.Item
            label="Line 3"
            name="line_3"
            rules={[
              {
                whitespace: true,
                message: "Input text",
              },
            ]}
          >
            <Input placeholder="Input text" />
          </Form.Item>
          <Form.Item
            label="Line 4"
            name="line_4"
            rules={[
              {
                whitespace: true,
                message: "Input text",
              },
            ]}
          >
            <Input placeholder="Input text" />
          </Form.Item>
          <Form.Item
            label="Line 5"
            name="line_5"
            rules={[
              {
                whitespace: true,
                message: "Input text",
              },
            ]}
          >
            <Input placeholder="Input text" />
          </Form.Item>
          <Form.Item
            label="Custom salutation"
            name="customSalutation"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Input text such as whom it may concern",
              },
            ]}
          >
            <Input placeholder="Input text such as whom it may concern" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        className="common-modal document-modal"
        title="Upload document"
        visible={show}
        onOk={handleSubmit}
        okButtonProps={{
          loading: loading,
        }}
        okText="upload"
        cancelText="cancel"
        onCancel={onCancel}
        forceRender
        centered
      >
        <Form form={form} layout="vertical" name="form_inking">
          <Form.Item
            label="document type"
            name="type"
            className="select-container"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Document Type",
              },
            ]}
          >
            <Select placeholder="Select">
              {[...documentOptions, docTypeOther].map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="document"
            noStyle
            rules={[
              {
                required: true,
                type: "array",
                message: "document",
              },
            ]}
          >
            <UploadCrop
              folder="document"
              listType="text"
              className="upload-item-wrap"
              showUploadList
              accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg"
              maxCount={1}
              crop={false}
            >
              <div className="children-upload">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Please upload 1 file at a time. Strictly prohibit from
                  uploading company data or other band files
                </p>
              </div>
            </UploadCrop>
          </Form.Item>
        </Form>
      </Modal>
      <MerbershipModal
        visible={membershipShow}
        width={420}
        footer={[
          <Button key="back" onClick={() => setMembershipShow(false)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            {getPopupButtonByMembershipStatus(membershipStatus)}
          </Button>,
        ]}
      >
        <h2>
          {getPopupTitleByMembershipStatus(
            membershipStatus,
            `One More Step to get affiliation letter`
          )}
        </h2>
        <p>
          {getPopupDescByMembershipStatus(
            membershipStatus,
            "Fund your artistic projects with tax-deductible donations and grants – and leave the paperwork to us."
          )}
        </p>
      </MerbershipModal>
    </DocumentContainer>
  );
};

export default Document;
