//created by Peggy on 2021/3/8
import React, {
  useImperativeHandle,
  useRef,
  useState,
  MutableRefObject,
} from "react";
import styled from "styled-components";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  getStripeSecret,
  donationMonthSubscribeStripe,
} from "@/api/donation-api";
import {
  getCustomer,
  grant_rush,
  recurring_change,
  renewByStripe,
  subscribeStripe,
  subscribeStripeChange,
} from "@/api/payment-api";
import { message, Spin } from "antd";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { useDebouncedCallback } from "use-debounce/lib";
const promise = loadStripe(process.env.REACT_APP_STRIPE_LOAD_KEY as string, {
  locale: "en",
});

const StripePayContainer = styled.div`
  .field-form-items {
    width: 100%;
    margin-bottom: 24px;
  }
  .flex > .field-form-items:first-child {
    margin-right: 24px;
  }
`;

interface Props {
  stripe?: any;
  elements?: any;
  payType?: any;
  secretId?: string;
  payId?: any;
  payInfo?: any;
  submitRef?: any;
  showRequired?: boolean;
  recurringData: { recurringDate: Date }
  setPaying?: (val: boolean) => void;
  onNext?: () => void;
  onFail?: () => void;
}

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#e0e0e0",
        fontSize: "13px",
      },
    },
    invalid: {
      color: "#f00",
    },
  },
};

const logEvent = (name: string) => (event: any) => {
  console.log(`[${name}]`, event);
};

// const Result = ({ children }: any) => (
//   <div
//     className="result"
//     style={{
//       fontSize: 12,
//       color: "#f00",
//     }}
//   >
//     {children}
//   </div>
// );

// const ErrorResult = ({ children }: any) => (
//   <div className="error">{children}</div>
// );

const StripeForm = ({
  payId,
  secretId,
  payInfo: {
    payment,
    planId,
    priceId,
    address,
    city,
    state,
    country,
    code,
    coupon,
    ...others
  },
  stripe,
  payType = "pay",
  elements,
  submitRef,
  recurringData,
  showRequired,
  setPaying = (val) => { },
  onNext = () => { },
  onFail = () => { }
}: Props) => {
  const submitBtnEl: MutableRefObject<any> = useRef();
  useImperativeHandle(submitRef, () => ({
    submit: () => submitBtnEl?.current?.click(),
  }));
  const {
    store: { isMobile },
  } = useModel(view);
  const [processing, setProcessing] = useState<boolean | string>("");
  // const [errorMessage, setErrorMessage] = useState<any>(null);

  // const [clientSecret, setClientSecret] = useState("");
  // const stripe: any = useStripe();
  // const elements: any = useElements();

  const subscribePay = async (cardElement: any) => {
    (payType === "subscribe" || payType === 'renew') && (await getCustomer());
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        ...others,
        address: {
          country,
          state,
          city,
          postal_code: code,
          line1: address,
          line2: null,
        },
      },
    } as any);

    if (result.error) {
      message.error(`Payment failed: ${result?.error?.message}`);
      // setErrorMessage(result?.error?.message);
      setProcessing(false);
      setPaying(false);
      onFail && onFail();
    } else {
      let payload: any;
      try {
      
        if (payType === "subscribe") {
          payload = await subscribeStripe({
            paymentMethodId: result.paymentMethod.id,
            priceId,
            coupon,
          });
        }else if (payType === "renew") {
          payload = await renewByStripe({
            paymentMethodId: result.paymentMethod.id,
            priceId,
            coupon,
          });
        } else if (payType === "donationMonthSubscribe") {
          payload = await donationMonthSubscribeStripe({
            paymentMethodId: result.paymentMethod.id,
            id: payId,
          });
        } else if (payType === 'recurringChange') {
          payload = await recurring_change({
            paymentMethodId: result.paymentMethod.id,
            orderId: payId,
            ...recurringData
          }, 'stripe')
        } else if (payType === 'grant_rush') {
          payload = await grant_rush({
            paymentMethodId: result.paymentMethod.id,
            orderId: payId,
            coupon,
          })
        } else {
          payload = await subscribeStripeChange({
            paymentMethodId: result.paymentMethod.id,
            priceId,
          });
        }
      } catch (error) {
          onFail && onFail()
      }
      if (payload?.success) {
        // setErrorMessage(null);
        setProcessing(false);
        onNext();
      }
    }
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    if (processing) {
      return false
    }
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (payType !== "pay") {
      await subscribePay(cardElement);
      return false;
    }
    let clientSecret = await getSecret();
    setProcessing(true);

    const payload: any = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          ...others,
          address: {
            country,
            state,
            city,
            postal_code: code,
            line1: address,
            line2: null,
          },
        },
      } as any,
    });
    if (payload.error) {
      message.error(`Payment failed: ${payload?.error?.message}`);
      // setErrorMessage();
      setProcessing(false);
      setPaying(false);
      onFail && onFail()
    } else {
      onNext();
      // setErrorMessage(null);
      setProcessing(false);
    }
  };

  const debounceSubmit = useDebouncedCallback(handleSubmit, 1500, {leading: true, trailing: false})
  // const submitPay = () => debounceSubmit.flush()

  const getSecret = async () => {
    if (secretId) {
      return secretId;
    }
    let { client_secret }: any = await getStripeSecret({
      id: payId,
    });
    return client_secret;
  };

  return (
    <>
      {processing && <Spin className="global-spin-container"></Spin>}
      <StripePayContainer className="stripe-pay-container">
        <form id="payment-form" onSubmit={debounceSubmit}>
          <div className={`field-form-items ${showRequired? 'required':''}`}>
            <span>card number</span>
            <CardNumberElement
              id="cardNumber"
              onBlur={() => logEvent("blur")}
              onChange={() => logEvent("change")}
              onFocus={() => logEvent("focus")}
              onReady={() => logEvent("ready")}
              options={{
                showIcon: true,
                ...ELEMENT_OPTIONS,
                placeholder: "**** **** **** 0000",
              }}
            />
          </div>
          <div className={`${isMobile ? "" : "flex"}`}>
            <div className={`field-form-items ${showRequired? 'required':''}`}>
              <span>Expiration Date</span>
              <CardExpiryElement
                id="expiry"
                onBlur={() => logEvent("blur")}
                onChange={() => console.log("change")}
                onFocus={() => logEvent("focus")}
                onReady={() => logEvent("ready")}
                options={ELEMENT_OPTIONS}
              />
            </div>
            <div className={`field-form-items ${showRequired? 'required':''}`}>
              <span>cvc</span>
              <CardCvcElement
                id="cvc"
                onBlur={() => logEvent("blur")}
                onChange={() => logEvent("change")}
                onFocus={() => logEvent("focus")}
                onReady={() => logEvent("ready")}
                options={ELEMENT_OPTIONS}
              />
            </div>
          </div>
          {/*{errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}*/}
          <button
            style={{ display: "none" }}
            type="submit"
            ref={submitBtnEl}
            id="submit-btn"
            disabled={!stripe}
          >
            Pay now
          </button>
        </form>
      </StripePayContainer>
    </>
  );
};

const StripeFormHoc = React.forwardRef<any, any>((props, ref) => (
  <StripeForm {...props} submitRef={ref} />
));

const StripePay = (props: any) => {
  const submitRef = useRef<any>(null);
  useImperativeHandle(props.submitRef, () => ({
    submit: submitRef?.current?.submit,
  }));
  return (
    <Elements stripe={promise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <StripeFormHoc
            {...props}
            stripe={stripe}
            elements={elements}
            ref={submitRef}
          />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default StripePay;
