//created by Peggy on 2021/3/11
import React, { useEffect, useState } from "react";

import DatePicker from "@/components/DatePicker";

import PieCard from "@/pages/dashboard/income/PieCard";
import FundCard from "@/pages/dashboard/income/FundCard";
import CampaignCard from "@/pages/dashboard/income/CampaignCard";
import GrantCard from "@/pages/dashboard/income/GrantCard";

import { artistOverView } from "@/api/artist-api";

import "@/styles/dashboard/income.less";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import dayjs from "dayjs";
import { donationTypes } from "@/types/enum";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";
import { Alert } from "antd";

const dateFormat = "YYYY-MM-DD";
const Index = () => {
  const {
    store: { info },
  } = useModel(user);
  const [val, setVal] = useState<any>({});
  const [stats, setStatus] = useState<any>();
  useEffect(() => {
    const { start = undefined, end = undefined } = val;
    artistOverView({
      start,
      end,
    }).then((data: any) => {
      const _temp = data?.income?.map((item: any) => {
        item.type = donationTypes[item.type];
        return item;
      });
      setStatus({ ...data, income: _temp });
    });
  }, [val]);
  return (
    <div className="income-overview-container common-container">
      <Alert message={<>
        The Field is taking a collective, organizational pause from August 26 to September 2, resuming work on September 3, 2024. Please allow additional time for a response from our team during this period. All contributions received during this time will still be disbursed no later than 10 business days after the date of receipt. If you intend on submitting a <span style={{color:'red'}}>fiscal sponsorship application, solicitation letter, benefit invitation, or grant proposal</span> please take this break into consideration when planning your timeline. We thank you for your patience and look forward to a reinvigorating return after Labor Day.
        </>} closable type="warning" showIcon/>
      <h1>Welcome to <TheField/>, <NoTranslate> {info?.firstName}</NoTranslate>!</h1>
      <div className="range-picker-wrap field-form-items">
        <span>Date range</span>
        <DatePicker.RangePicker
          dropdownClassName="date-picker-mobile"
          onChange={(e) => {
            const [start, end] = e || [null, null];
            setVal({
              ...val,
              start: start ? start.format(dateFormat) : undefined,
              end: end ? end.format(dateFormat) : undefined,
            });
          }}
        />
      </div>
      <div className="card-wrap">
        <div className="flex card-row">
          <PieCard data={stats?.income} />
          <FundCard start={val.start} end={val.end} />
        </div>
        <div className="flex card-row">
          <CampaignCard data={stats?.crowdfunding} />
          {/*<GrantCard />*/}
        </div>
      </div>
    </div>
  );
};

export default Index;
