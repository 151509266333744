import Holidays from "date-holidays";
import React from "react";
import { useLocation } from "react-router";
const relativeTime = require("dayjs/plugin/relativeTime");
const duration = require("dayjs/plugin/duration");
var isBetween = require('dayjs/plugin/isBetween')
const dayjs = require("dayjs");
dayjs.extend(isBetween)
dayjs.extend(relativeTime);
dayjs.extend(duration);

export const flatten = function (obj: any) {
    let result: any = {};

    function recurse(src: any, prop: string) {
        if (Array.isArray(src)) {
            let len = src.length;
            if (len > 0) {
                src.forEach(function (item: any, index: number) {
                    recurse(item, prop ? prop + '.[' + index + ']' : index + '');
                })
            } else {
                result[prop] = [];
            }
        } else if (typeof src === 'object') {
            let isEmpty = true;
            for (let p in src) {
                isEmpty = false;
                recurse(src[p], prop ? prop + '.' + p : p)
            }
            if (isEmpty && prop) {
                result[prop] = undefined;
            }
        } else {
            result[prop] = src;
        }
    }
    recurse(obj, '');

    return result;
}
export const unflatten = function (data: any): any {
    if (Object(data) !== data || Array.isArray(data))
        return data;
    let regex = /\.?([^.[\]]+)|\[(\d+)\]/g
    let resultholder: any = {};
    for (let p in data) {
        let cur = resultholder
        let prop = ""
        let m;
        while (m = regex.exec(p)) {
            cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
            prop = m[2] || m[1];
        }
        cur[prop] = data[p];
    }
    return resultholder[""] || resultholder;
}

let holidays: Holidays// from CDN
export const businessDays = (deadline: string) => {
    const now = dayjs()
    const duration = dayjs.duration(dayjs(deadline).diff(now));
    let days = Math.ceil(duration.asDays());

    if (Holidays) {
        console.log('holidays loaded')
        holidays = holidays || new Holidays({ country: 'US' }, { timezone: dayjs.tz.guess() })
        let i = days;
        while (i-- > 0) {
            const date = dayjs().add(i, 'day')
            let hday = holidays.isHoliday(date.toDate())
            if (hday && hday[0]?.type === 'public') {
                console.log('find one holiday', hday[0])
                days--
            }else if(date.day() === 0 || date.day() === 6){
                console.log('Remove weekend')
                days--
            }else if(date.isBetween('2024-8-26', '2024-9-02','day','[]')){
                console.log('According to item #891, need to remove those 5 days')
                days--
            }
        }
        return days
    }
    return 0;
}

export const isRushGrant = (deadline: string, rush_days = 10) => {
    const now = dayjs()
    const duration = dayjs.duration(dayjs(deadline).diff(now));
    const days = Math.ceil(duration.asDays());

    let space = days - rush_days;
    if (Holidays) {
        console.log('holidays loaded')
        holidays = holidays || new Holidays({ country: 'US' }, { timezone: dayjs.tz.guess() })
        let i = days;
        while (i-- > 0 && space > 0) {
            const date = dayjs().add(i, 'day')
            let hday = holidays.isHoliday(date.toDate())
            if (hday && hday[0]?.type === 'public') {
                console.log('find one holiday', hday[0])
                space--
            }else if(date.day() === 0 || date.day() === 6){
                console.log('Remove weekend')
                space--
            }else if(date.isBetween('2024-8-26', '2024-9-02','day','[]')){
                console.log('According to item #891, need to remove those 5 days')
                space--
            }
        }
        return space <= 0;
    }
    return false;
}

export const getQuery = (name:string) => {
  const query = new URLSearchParams(window.location.search)
  return query.get(name);
}

export const sleep = (ms:number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}