//created by Peggy on 2021/3/24
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Breadcrumb, Button, Divider, Form, Input, message, Modal } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import { useHistory } from "react-router-dom";
import { grantList, grantApply, grantDetail } from "@/api/grant-api";
import qs from "query-string";

import SelectCard from "@/components/Select/SelectCard";
import { localStorageGet } from "@/utils/storage";
import icon_donation from "@/images/icons/icon_donation.svg";
import Info from "@/components/Modal/Info";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { businessDays, isRushGrant } from "@/utils/util";
import { TheField } from "@/components/GoogleTranslate";
import { getGrantApply, getGrantApplyNotes } from "@/adminApp/apis/grant-api";
const relativeTime = require("dayjs/plugin/relativeTime");
const duration = require("dayjs/plugin/duration");
const dayjs = require("dayjs");
dayjs.extend(relativeTime);
dayjs.extend(duration);

const CreateContainer = styled.div`
.document-wrap {
  display: flex;
  align-items: center;
  &:after {
    display: inline-block;
    width: 12px;
    height: 12px;
    font-size:12px;
    line-height: 12px;
    margin-top: 6px;
    margin-left: 6px;
    content: '*';
    color: #ff4d4f;
  }
}
.adminNotes{
  border: 1px solid #e0e0e0;
  padding: 9px 16px;
}
.upload-item-wrap .ant-upload {
  width: 100%;
}
`;
type Note = {
  id: number;
  type: "link" | "text";
  content: string;
};
const Create = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { request, mode, applyId } = qs.parse(history.location.search);
  const userInfo = localStorageGet("info");
  const { store: { isMobile } } = useModel(view)
  //modal
  const [showdays, setShowdays] = useState<undefined | number>();

  const [loading, setLoading] = useState<boolean>(false);
  const [noteTxt, setNoteTxt] = useState<Note>();
  const onFinish = (values: any) => {
    let {
      grant: { id, deadline, deadlineType, name: grantName },
      documents,
      ...others
    } = values;

    const rush = isRushGrant(deadline)
    if (showdays === undefined && deadlineType === "hard" && rush) {
      const days = businessDays(deadline)
      setShowdays(days);
      return false;
    }
    let _data = {
      ...others,
      id,
      documents: documents.map((item: any) => item.url),
      rush,
      mode: mode
    };
    setLoading(true);

    grantApply(_data)
      .then((data) => {
        setShowdays(undefined);
        if(data?.orderPaid){
          setShow(true)
          return
        }
        if(!_data.rush){
          setShow(true)
          return
        }
        history.push(`/dashboard/grant/${data.order?.id}/${data?.rushFee}/${grantName}`)
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e?.errorFields[0]?.errors[0]);
  };

  //search
  const [options, setOptions] = useState<any[]>([]);
  const handleSearch = (keyword: string) => {
    let _data = {
      keyword,
    };
    grantList(_data).then((data) => setOptions(data.grants));
  };

  useEffect(() => {
    initData()
    // eslint-disable-next-line
  }, [request]);

  const initData = async ()=>{
    setLoading(true);
    const info = await grantDetail(request as string)
    if (info?.id) {
      form.setFieldsValue({
        grant: info,
        contactName: `${userInfo?.firstName} ${userInfo.lastName}`,
      });
      if(mode === 'Update'){
        const applyData = await getGrantApply(Number(applyId));
        const notes = await getGrantApplyNotes(Number(applyId))
        let txt: Note = undefined as any
        const _data: any = notes.items.map((item: any) => {
          if (item.type === 'link') {
            return {
              uid: item.id,
              name: item.content,
              status: 'done',
              url: item.content
            }
          } 
          else if (!txt) {
            txt = item
          }
        })
        txt && setNoteTxt(txt)
        form.setFieldsValue({ notes:applyData?.notes, documents: _data.filter(Boolean) })
      }
      
      setLoading(false);
    }
  }

  //info
  const [show, setShow] = useState(false);
  return (
    <CreateContainer className="event-create-container common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/grant">
          Grant applications
        </Breadcrumb.Item>
        <Breadcrumb.Item>Request grant review</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Request grant application review </h1>
      <p>
        In order to apply for a grant using <TheField/> as your Fiscal Sponsor,
        you will have to submit the content for <TheField/> to review. The review
        process will take no more than 10 business days.
      </p>
      <Form
        layout="vertical"
        name="campaign"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="event-form">
          <h2>Grant information</h2>
          <Form.Item
            name="grant"
            noStyle
            rules={[
              {
                required: true,
                message: "Select a grant",
              },
            ]}
          >
            <SelectCard
              disable={mode === 'Update'}
              options={options}
              onSearch={(key: string) => handleSearch(key)}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues?.grant?.id !== currentValues?.grant?.id) {
                console.log(form.getFieldValue("grant"));
                setShowdays(undefined);
                return true;
              } else {
                return false;
              }
            }}
          >
            {() => {
              return (
                <div
                  className="field-form-items org-item"
                  style={{ width: "100%" }}
                >
                  <span>organization’s url</span>
                  <Input
                    value={form?.getFieldValue("grant")?.organization}
                    maxLength={50}
                    disabled
                    placeholder="Enter grant’s host URL"
                  />
                </div>
              );
            }}
          </Form.Item>

          <Divider />
          <h2>Contact information</h2>
          <Form.Item
            label="contact name"
            name="contactName"
            normalize={(e) => {
              return e.replace(/[^\w\s]*/g, "");
            }}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Enter contact name",
              },
            ]}
          >
            <Input maxLength={100} placeholder="Enter contact name" />
          </Form.Item>
          <Form.Item
            className="textarea-wrap"
            label="notes"
            name="notes"
            rules={[
              {
                required: true,
                whitespace: false,
                message: "Enter notes",
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              maxLength={300}
              placeholder="Enter your notes"
            />
          </Form.Item>

          <Divider />
          <h2 className="document-wrap">Documents</h2>
          <Form.Item
            label=""
            name="documents"
            noStyle
            rules={[
              {
                required: true,
                type: "array",
                message: "documents",
              },
            ]}
          >
            <UploadCrop
              listType="text"
              className="upload-item-wrap"
              showUploadList
              accept=".pdf,.doc,.docx,.xlsx,.csv,.png,.jpeg,.jpg"
              maxCount={15}
              folder="grant"
              crop={false}
            >
              <div className="children-upload">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                Upload 1 file at a time. We support .pdf,.xls, .xlsx, .doc, .docx, .png, .jpeg. File size should be under 100MB
                </p>
              </div>
            </UploadCrop>
          </Form.Item>
          { mode === 'Update' && noteTxt?.content && <>
            <h2>Admin notes</h2>
            <div className="adminNotes" >{noteTxt?.content}</div>
          </>}
          
        </div>
        <p className="flex end btn">
          <Button
            loading={loading}
            type="primary"
            style={isMobile ? { flex: 1 } : {}}
            onClick={() => form.submit()}
          >
            { mode === 'Update' ? 'Update' : 'Apply'}
          </Button>
        </p>
      </Form>
      <Modal
        // title={`This grant ends in ${showdays} days!`}
        title="You are requesting a Rush Review of this application."
        wrapClassName="grant-confirm-modal"
        visible={!!showdays}
        okText="request rush application"
        width={570}
        onCancel={() => {
          setShowdays(undefined);
        }}
        onOk={() => {
          form.submit();
          setShowdays(0);
        }}
      >
        You're submitting materials for review less than 10 business days prior to the grant deadline.
         If you would like to proceed with this request, you agree to pay a $75 rush review fee. 
         <br />
         Late Rush application reviews are subject to availability. 
         We will only charge your credit card if your rush application request is approved.
      </Modal>
      <Info
        className="event-info-modal"
        visible={show}
        width={660}
        footer={[
          <Button onClick={() => history.push("/dashboard/message")}>
            contact us
          </Button>,
          <Button
            onClick={() => history.push("/dashboard/grant")}
            type="primary"
          >
            Got it
          </Button>,
        ]}
        img={icon_donation}
      >
        <h1>Your grant review request has been received!</h1>
        <p>
          We have received your application and will confirm your eligibility.
          Once the review is completed, you will receive an email with next
          steps.
        </p>
      </Info>
    </CreateContainer>
  );
};

export default Create;
