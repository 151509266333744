import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { KV, useModel } from "use-reaction";
import {
  Form,
  Input,
  Select,
  Button,
  Tooltip,
  Menu,
  message,
  Breadcrumb,
  Tag,
  Divider,
} from "antd";

import TextArea from "antd/lib/input/TextArea";

import {
  admin_router_crowd_field,
  admin_router_crowd_field_donation_detail,
  admin_router_finance_contribution_order,
  admin_router_finance_contribution_order_note,
} from "@/adminApp/routers";

import {
  contributionDetail,
  contributionSave,
  contributionTag,
} from "@/adminApp/apis/finance-api";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import "./detail.less";
import DatePicker from "@/components/DatePicker";

import dayjs from "dayjs";
import { donationProductTypes, paymentTypes, statusTypes } from "@/types/enum";
import { artistList } from "@/adminApp/apis/artist-api";
import RemoteInput from "@/components/Input/RemoteInput";
import { localStorageGet } from "@/utils/storage";
import { UserRole } from "@/pages/dashboard/myMsg/myMsg";
import AmountInput from "@/components/Input/AmountInput";
import { view } from "@/model/view";
import { stateList } from "@/types/enum";
import { OrderPdf } from "@/components/OderPdf/OrderPdf";
import qs from 'query-string'
import { getCrowdList } from "@/adminApp/apis/event-api";
import DynamicSelect from "@/components/Input/DynamicSelect";
const { getData: getCountryList, getCode: getCountryCode } = require('country-list');
const countryList = getCountryList()

export enum SponsorshipStatus {
  NO_APPLY = "no_apply",
  IN_REVIEW = "in_review",
  APPROVED = "approved",
  REJECTED = "rejected",
  PAID = "paid",
  COMPLETED = "completed",
}
type ArtistInfo = {
  disciplines: string[];
  website: string;
  biography: string;
  missionAndGoals: string;
};

type PersonInfo = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string[];
};
type SponsorInfo = {
  id: number;
  artistInformation?: ArtistInfo;
  personalInformation?: PersonInfo;
  signatureInfo?: KV;
  status?: SponsorshipStatus;
  note?: string;
  userId?: number;
};
const initInfo: SponsorInfo = {
  id: 0,
};

const donationTypeOption: any = {
  donation_to_artist: 'General fundraising',
  crowdfunding: 'Crowdfunding fundraising',
}

const ContributionOrder = () => {
  const history = useHistory();
  let { id, edit } = useParams() as any;
  id = parseInt(id);
  const { crowd } = qs.parse(history.location.search)
  const { store: { isMobile } } = useModel(view)

  const [info, setInfo] = useState<any>(initInfo);
  const [formRef] = Form.useForm();
  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);
  const [crowdfunds,setCrowdfunds] = useState([]);
  const loadInfo = async () => {
    const { schedulePaymentDate, artist, ...others } = await contributionDetail(
      id
    );
    setInfo({
      ...others,
      schedulePaymentDate:
        (schedulePaymentDate &&
          dayjs(schedulePaymentDate).format("MM/DD/YYYY")) ||
        "",
      artist: [
        {
          value: artist?.id || "",
          label: artist?.name || "N/A",
        },
      ],
    });
    const {
      createDate,
      issuedBy: { address: addressB, email: emailB, name: nameB },
      issuedTo: { address: addressT, addressInfo, email: emailT, name: nameT },
      approveQuickBook,
      tags,
      type,
      ...other
    } = others;
    
    formRef.setFieldsValue({
      ...other,
      createDate: other?.paymentMethod === 'check' ? (other?.status === 'check_pending' ? null : dayjs(other?.billDate)) : dayjs(other?.createDate),
      addressB,
      emailB,
      nameB,
      addressT,
      ...(addressInfo ? {
        addressT_address: addressInfo.address,
        addressT_city: addressInfo.city,
        addressT_country: getCountryCode(addressInfo.country || 'US'),
        addressT_state: addressInfo.state,
        addressT_zipCode: addressInfo.zipCode,
      } : {}),
      emailT,
      nameT,
      approveQuickBook: approveQuickBook ? 1 : 0,
      artistId: artist?.id,
      tags: !!tags ? tags : [],
      productType: getProductTypeVal(type),
      donationType: type,
      schedulePaymentDate:
        (schedulePaymentDate && dayjs(schedulePaymentDate)) || "",
    });
  };
  const getProductTypeVal = (val: string) => {
    return donationProductTypes[val] || 'other'
  }

  const onFinish = async (values: any) => {
    const {
      addressB,
      emailB,
      nameB,
      addressT,
      addressT_address,
      addressT_city,
      addressT_country,
      addressT_state,
      addressT_zipCode,
      emailT,
      nameT,
      approveQuickBook,
      ...others
    } = values;
    const _data = {
      issuedBy: { address: addressB, email: emailB, name: nameB },
      issuedTo: {
        ...(addressT_address ? {
          address: addressT_address,
          country: addressT_country,
          city: addressT_city,
          state: addressT_state,
          zipCode: addressT_zipCode,
        } : { address: addressT }),
        email: emailT,
        name: nameT
      },
      approveQuickBook: !!(approveQuickBook - 0),
      ...others,
    };
    const res = await contributionSave(id, _data);
    if (res?.success) {
      message.success("save succeed!");
      loadInfo();
      history.replace(
        admin_router_finance_contribution_order.replace(":id/:edit(edit)?", id)
      );
    }
  };

  useEffect(() => {
    loadInfo();
    // eslint-disable-next-line
  }, [id]);

  //handler end

  const [tagList, setTagList] = useState<any[]>([]);
  const getTags = () => {
    contributionTag().then((data) => setTagList(data));
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <div className="sponsorship-review">
      {
        crowd ? <Breadcrumb separator=">">
          <Breadcrumb.Item
            href={admin_router_crowd_field}
          >
            The Field Crowdfunding
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href={admin_router_crowd_field_donation_detail.replace(':id', crowd.toString())}
          >
            Campaign detail
          </Breadcrumb.Item>
        </Breadcrumb> :
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              href={isAdmin ? "/finance/contribution" : "/dashboard/transactions"}
            >
              Contributions
            </Breadcrumb.Item>
            <Breadcrumb.Item>Order details</Breadcrumb.Item>
          </Breadcrumb>
      }

      <div className="flex top-bar">
        <h2 className="title flex">
          Invoice {info?.orderId}
          {info?.status && (
            <Tag
              className="status-tag"
              color={
                statusTypes[info?.status]
                  ? statusTypes[info?.status]?.color
                  : "gray"
              }
            >
              {statusTypes[info?.status]
                ? statusTypes[info?.status]?.label
                : info?.status}
            </Tag>
          )}
        </h2>

        {isAdmin && (
          <Tooltip
            className="action-tool"
            placement="bottomLeft"
            overlay={
              <Menu>
                <Menu.Item
                  key="note"
                  onClick={() => {
                    const page = admin_router_finance_contribution_order_note.replace(
                      ":id",
                      id
                    ) + (crowd ? `?crowd=${crowd}` : '')
                    console.log('ddkdkdk',page)

                    history.push(page)
                  }
                  }
                >
                  View thank you note
                </Menu.Item>
                {!edit && (
                  <Menu.Item
                    onClick={() =>
                      history.replace(history.location.pathname + "/edit")
                    }
                  >
                    Edit
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <div className="action">
              <FilterMore />
            </div>
          </Tooltip>
        )}
      </div>
      {/*<div className="des">*/}
      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
      {/*  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim*/}
      {/*  veniam, quis nostrud exercitation ullamco.*/}
      {/*</div>*/}

      <div className={`review-card-container ${!edit ? "show" : "hidden"}`}>
        <div className="card-wrap">
          <h2 className="sub-title">Billing information</h2>
          <p className="flex">
            Invoice number
            <span>{info?.orderId}</span>
          </p>
          <p className="flex">
            Transaction ID
            <span>{info?.transactionId}</span>
          </p>
          <p className="flex">
            Payment method
            <span>{info?.paymentMethod}</span>
          </p>
          <p className="flex">
            Date paid
            <span>
              {info?.paymentMethod === 'check' ? (info?.status === 'check_pending' ? 'N/A' : dayjs(info?.billDate).format("MM/DD/YYYY")) : dayjs(info?.createDate).format("MM/DD/YYYY")}
            </span>
          </p>
        </div>
        <div className={`${isMobile ? '' : 'flex'}`}>
          <div className="card-wrap">
            <h2 className="sub-title">Issued to</h2>
            <p className="flex">
              Name
              <span translate="no">{info?.issuedTo?.name}</span>
            </p>
            <p className="flex">
              Email address
              <span translate="no">{info?.issuedTo?.email}</span>
            </p>
            <p className="flex">
              Billing address
              <span translate="no">{info?.issuedTo?.address}</span>
            </p>
          </div>
          <div className="card-wrap">
            <h2 className="sub-title">Issued By</h2>
            <p className="flex">
              Name
              <span translate="no">{info?.issuedBy?.name}</span>
            </p>
            <p className="flex">
              Email address
              <span translate="no">{info?.issuedBy?.email}</span>
            </p>
            <p className="flex">
              Billing address
              <span translate="no">{info?.issuedBy?.address}</span>
            </p>
          </div>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Invoice summary </h2>
          <p className="flex">
            Product type
            <span>{info?.productType}</span>
          </p>
          <p className="flex">
            Donation type
            <span>{info?.donationType || "N/A"}</span>
          </p>
          <p className="flex">
            Recipient Name
            <span>{info.type === 'fiscal_sponsorship' ? info?.user?.name : info?.artist?.[0]?.label || "N/A"}</span>
          </p>
          <p className="flex">
            Frequency
            <span>{info?.frequency === "once" ? "One time" : "Recurring"}</span>
          </p>
          <p className="flex">
            New or renewal
            <span>{info?.isNew}</span>
          </p>
          <p className="flex">
            Payment method
            <span>{info?.paymentMethod}</span>
          </p>
          <p className="flex">
            Amount
            <span>
              {Number(info?.amount ?? 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </p>
          <p className="flex">
            Tax
            <span>
              {Number(info?.tax ?? 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </p>
          <Divider />
          <h3 className="flex total-item">
            Total amount
            <span>
              {Number(
                Number(info?.tax ?? 0) + Number(info?.amount ?? 0)
              ).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </h3>
        </div>
        {/*<div className="card-wrap">*/}
        {/*  <h2 className="sub-title">Artist biography</h2>*/}
        {/*  <p>{info?.biography}</p>*/}
        {/*</div>*/}

        <div hidden={!isAdmin} className="card-wrap">
          <h2 className="sub-title">Donor note</h2>
          <p>{info?.donorNote}</p>
        </div>
        <div hidden={!isAdmin} className="card-wrap">
          <h2 className="sub-title">Admin note</h2>
          <p>{info?.adminNote}</p>
        </div>
        <div hidden={!isAdmin} className="card-wrap">
          <h2 className="sub-title">Transaction tags</h2>
          <p>{info?.tags}</p>
        </div>
        <div hidden={!isAdmin} className="field-form-items">
          <span>Approved for QB</span>
          <div>{info?.approveQuickBook ? "Yes" : "No"} </div>
        </div>
        <div hidden={!isAdmin} className="field-form-items">
          <span>Transaction status</span>
          <div>
            {(info?.status && (
              <Tag
                className="status-tag"
                color={statusTypes[info?.status].color}
              >
                {statusTypes[info?.status].label}
              </Tag>
            )) ||
              "N/A"}
          </div>
        </div>
        <div hidden={!isAdmin} className="field-form-items">
          <span>Scheduled payment date</span>
          <div>
            {info?.schedulePaymentDate ? info?.schedulePaymentDate : "N/A"}
          </div>
        </div>
        <div className="flex end">
          <OrderPdf detail={info} className="btn-pdf" />
        </div>

      </div>

      <Form
        form={formRef}
        onFinish={onFinish}
        layout="vertical"
        className={`${edit ? "show" : "hidden"}`}
        preserve={false}
      >
        <h2 className="sub-title">Billing information</h2>
        <Form.Item label="Payment method" name="paymentMethod">
          <Select disabled placeholder="select..." bordered={false}>
            {Object.entries(paymentTypes)
              ?.slice(0, 3)
              .map((item: any) => (
                <Select.Option key={item[0]} value={item[0]}>
                  {item[1]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Invoice number" name="orderId">
          <Input disabled placeholder="Enter invoice number" />
        </Form.Item>
        {info?.paymentMethod === "check" && (
          <>
            <Form.Item
              label="Check number"
              name="checkNumber"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Check number",
                },
              ]}
              normalize={(e: string) =>
                e
                  .replace(/[^\d]*/g, "")
                  .replace(/^(\d{11})/, (_) => _.substr(0, 10) + '-' + _[10])
                  .replace(/-(\d{8})/, (_) => _.substr(0, 8) + '-' + _[8])
              }
            >
              <Input placeholder="Enter check number" maxLength={24} />
            </Form.Item>
            <Form.Item
              label="Name on check"
              name="checkName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Name on check",
                },
              ]}
            >
              <Input placeholder="Enter name on check" />
            </Form.Item>
            <Form.Item label="Date Written on Check" name="checkDate">
              <DatePicker allowClear />
            </Form.Item>
          </>
        )}
        <Form.Item label="transaction id" name="transactionId">
          <Input disabled placeholder="Enter transaction id" />
        </Form.Item>
        <Form.Item label="date paid" name="createDate">
          <DatePicker disabled allowClear placeholder="Date paid" />
        </Form.Item>
        <Divider />
        <h2 className="sub-title">Issued to</h2>
        <Form.Item label="name" name="nameT">
          <Input disabled={info?.paymentMethod !== "check"} placeholder="Enter name" />
        </Form.Item>
        <Form.Item label="Email address" name="emailT">
          <Input disabled={info?.paymentMethod !== "check"} placeholder="Enter email address" />
        </Form.Item>
        {
          info?.paymentMethod === 'check' && <>
            <Form.Item
              label="address"
              name="addressT_address"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Address",
                },
              ]}
            >
              <Input disabled={info?.paymentMethod !== "check"} maxLength={200} placeholder="Address" />
            </Form.Item>
            <Form.Item
              label="City"
              name="addressT_city"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "City",
                },
              ]}
            >
              <Input disabled={info?.paymentMethod !== "check"} placeholder="City" maxLength={30} />
            </Form.Item>
            <Form.Item
              label="Country"
              name="addressT_country"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Choose Country",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                placeholder="Country"
              >
                {Object.values(countryList)?.map((item: any) => (
                  <Select.Option value={item.code} key={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle dependencies={['addressT_country']}>
              {
                ({ getFieldValue }) => {
                  return getFieldValue('addressT_country') === 'US' ?
                    <Form.Item
                      label="State"
                      name="addressT_state"
                      className="select-item"
                      rules={[
                        {
                          required: true,
                          whitespace: false,
                          message: "Select State",
                        },
                      ]}
                    >

                      <Select
                        showSearch
                        size="large"
                        placeholder="State"
                      >
                        {Object.values(stateList)?.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item> :
                    <Form.Item
                      label="State"
                      name="addressT_state"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Enter State",
                        },
                      ]}
                    >
                      <Input size="large" placeholder="State" />
                    </Form.Item>
                }
              }
            </Form.Item>
            <Form.Item
              label="Zip Code"
              name="addressT_zipCode"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  message: "Zip Code",
                },
              ]}
            >
              <Input disabled={info?.paymentMethod !== "check"} placeholder="Zip Code" maxLength={5} />
            </Form.Item>
          </>
        }

        <Divider />
        <h2 className="sub-title">Issued by</h2>
        <Form.Item label="name" name="nameB">
          <Input disabled placeholder="Enter name" />
        </Form.Item>
        <Form.Item label="billing address" name="addressB">
          <Input disabled placeholder="Enter billing address" />
        </Form.Item>
        <Form.Item label="Email address" name="emailB">
          <Input disabled placeholder="Enter email address" />
        </Form.Item>
        <Divider />
        <h2 className="sub-title">Invoice summary</h2>
        <Form.Item label="product type" name="productType">
          <Select
            disabled={info?.paymentMethod !== "check"}
            bordered={false}
            placeholder="Select product types"
          >
            <Select.Option value="field">Donate to The Field</Select.Option>
            <Select.Option value="artist">Donate to Artist</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Artist name" name="artistId">
          <RemoteInput
            defaultOptions={info?.artist}
            disabled={info?.paymentMethod !== "check"}
            placeholder="Search for SA Artist"
            fetchOptions={async (e) => {
              let data = await artistList({
                keyword: e,
                page: 1,
                limit: 1000,
              });
              return data?.items.map((item: any) => ({
                value: item.userId,
                label: item.firstName + item?.lastName,
              }));
            }}
          />
        </Form.Item>
        <Form.Item label="donation type" name="donationType">
          <Select
              disabled={!(["donation_to_artist", "crowdfunding"].includes(info?.type))}
              bordered={false}
              placeholder="Select donation types"
          >
            {Object.entries(donationTypeOption).map(([k, v]: [string, any]) => (
              <Select.Option key={k} value={k}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => currentValues.donationType !== prevValues.donationType}
        >
          {({ getFieldValue }) => {
            if(getFieldValue('donationType') !== 'crowdfunding'){
              return
            }
            return <Form.Item name={['crowdfunding',"id"]} label="Crowdfunding" rules={[{ required: true }]}>
              <DynamicSelect placeholder="Select Crowdfunding" fetchOptions={async ()=>{
                    if(!info.artist?.[0]?.value){
                      return []
                    }
                    const data = await getCrowdList({user_id:info.artist?.[0]?.value})
                    return data.map((e:any) => ({label:e.name,value:e.id}));
              }} />
              </Form.Item>
              }
          }
        </Form.Item>
        <Form.Item label="frequency" name="frequency">
          <Select
            disabled={info?.paymentMethod !== "check"}
            bordered={false}
            placeholder="Select product types"
          >
            <Select.Option value="once">One time</Select.Option>
            <Select.Option value="month">Recurring monthly</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Amount" name="amount">
          <AmountInput disabled={info?.paymentMethod !== "check"} placeholder="Enter amount" />
        </Form.Item>
        <Form.Item label="tax" name="tax">
          <Input
            disabled={info?.paymentMethod !== "check"}
            placeholder="Enter Website"
          />
        </Form.Item>

        <Divider />
        <h2 className="sub-title">Donor note</h2>
        <Form.Item
          label="Donor note"
          name="donorNote"
          className="textarea-wrap"
        >
          <TextArea placeholder="Enter donor note" />
        </Form.Item>

        <Divider />
        <h2 className="sub-title">Admin note</h2>
        <Form.Item
          label="Admin note"
          name="adminNote"
          className="textarea-wrap"
        >
          <TextArea placeholder="Enter admin note" />
        </Form.Item>

        <Divider />
        <h2 className="sub-title">Transaction tags</h2>
        <Form.Item label="Transaction tags" name="tags">
          <Select
            allowClear
            placeholder="Select transaction tags"
            mode="multiple"
            bordered={false}
          >
            {tagList.map((item: any) => (
              <Select.Option key={item.name} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />
        <Form.Item
          label="Approved for QB"
          name="approveQuickBook"
          initialValue={0}
        >
          <Select allowClear placeholder="select..." bordered={false}>
            <Select.Option value={1}>Yes</Select.Option>
            <Select.Option value={0}>No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Transaction status" name="status">
          <Select allowClear placeholder="select..." bordered={false}>
            {Object.entries(statusTypes).map((item: any) => (
              <Select.Option key={item[0]} value={item[0]}>
                {item[1].label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Scheduled payment date" name="schedulePaymentDate">
          <DatePicker allowClear />
        </Form.Item>
      </Form>
      <div className="row flex end">
        {edit ? (
          <Button
            onClick={() => formRef.submit()}
            type="primary"
            className="btn-draft"
          >
            Save Changes
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ContributionOrder;
