//created by Peggy on 2021/3/19
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import dayjs from "dayjs";

import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Tag,
  Tooltip,
  Modal as AntModal,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";

import InvoiceTable from "@/pages/dashboard/donation/InkindTable";
import Search from "@/pages/dashboard/donation/Search";
import Modal from "@/components/Modal/Info";

import { getSponsor, cancelSubscription } from "@/api/merbership-api";
import { subscribeInfo, subscribeStripeChange } from "@/api/payment-api";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import "@/styles/dashboard/mebership.less";
import styled from "styled-components";
import { localStorageGet } from "@/utils/storage";
import useConfirm from "@/components/Modal/Confirm";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { paymentTypes, statusTypes } from "@/types/enum";
import { myTransactions } from "@/api/donation-api";
import queryString from "query-string";
import { LabelInput } from "@/components/LabelInput/LabelInput";
import { couponCheck } from "@/api/consultation-api";
import {
  getPopupButtonByMembershipStatus,
  getPopupDescByMembershipStatus,
  getPopupTitleByMembershipStatus,
} from "@/utils/common";
import { TheField } from "@/components/GoogleTranslate";
import IsThisCorrect from "@/pages/homepage/donation/IsThisIncorrect";
const { getName: getCountryName } = require("country-list");

const OverviewContainer = styled.div``;
const dateFormat = "MM/DD/YYYY";
const Overview = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [selectOption] = useState<any>();
  const {
    store: { isMobile },
  } = useModel(view);

  //show
  const [show, setShow] = useState<boolean | undefined>();
  //status
  const [sponsor, setSponsor] = useState<any>();
  const [bill, setBill] = useState<any>();
  const getStatus = () => {
    let _data = {};
    getSponsor(_data).then((data: any) => {
      if (!["completed", "paid", "wait_for_signature"].includes(data?.status)) {
        setShow(true);
      } else {
        setShow(false);
      }
      const graceDays = data.currentPlan?.title === "Monthly" ? 2 : 14;
      const isInGracePeriod =
        data.status === "completed" && dayjs(data.expireDate).isBefore(dayjs());
      // const graceExpireDate = dayjs(data.expireDate).add(graceDays, 'day').format(dateFormat);
      // const graceExpireRemains = dayjs(data.expireDate).add(graceDays, 'day').diff(dayjs(), 'day');
      // use real-expire-date
      const graceExpireDate = data.expireDate ? dayjs(data.expireDate).format(dateFormat) : '';
      const graceExpireRemains = dayjs(data.expireDate).diff(dayjs(), "day");
      const renewAnnualNextBillingDate = dayjs(
        isInGracePeriod ? new Date() : data.expireDate
      )
        .add(1, "year")
        .format(dateFormat);
      const renewMonthNextBillingDate = dayjs(
        isInGracePeriod ? new Date() : data.expireDate
      )
        .add(1, "month")
        .format(dateFormat);
      const renewPlusDays = isInGracePeriod
        ? 0
        : dayjs(data.expireDate).diff(dayjs(), "day");
      const scheduleChargeDate =
        isInGracePeriod || dayjs(data.expireDate).isSame(dayjs(), "day")
          ? "today"
          : "on " + dayjs(data?.expireDate).format(dateFormat);

      setSponsor({
        ...data,
        isInGracePeriod,
        graceExpireDate,
        graceExpireRemains,
        renewAnnualNextBillingDate,
        renewMonthNextBillingDate,
        renewPlusDays,
        scheduleChargeDate,
      });
    });
  };

  const onLoad = () => {
    getStatus();
    subscribeInfo().then((data) => {
      if (data.type === "none") {
        form.setFieldsValue({
          name: null,
          email: null,
          city: null,
          address: null,
          state: null,
          country: null,
          code: null,
        });
        setBill({});
        return;
      }
      if (data.type === "stripe") {
        let {
          name,
          email,
          address: { city, line1: address, postal_code: code, state, country },
        } = data?.billingInformation;
        form.setFieldsValue({
          name,
          email,
          city,
          address,
          state,
          country: getCountryName(country || "US"),
          code,
        });
      } else {
        let { name, email } = data;
        form.setFieldsValue({
          name,
          email,
        });
      }
      setBill(data);
    });
  };
  useEffect(onLoad, []);

  const { setShow: setPopup, component } = useConfirm(
    <div className="flex column start align-start">
      <p>
        By clicking Cancel subscription, you will no longer have access to
        Fiscal sponsorship features. You will lose access to all data related to
        your events, donations and all other premium features. Your data will be
        stored within 6 months. Your payment will be cancelled on{" "}
        {(sponsor?.currentPlan?.nextBillingDate &&
          dayjs(sponsor?.currentPlan?.nextBillingDate).format(dateFormat)) ||
          "N/A"}
        .
      </p>
      <p>
        Please contact support@thefield.org if you want to restore the plan.{" "}
      </p>
    </div>,
    {
      title: "Are you sure you want to cancel ? ",
      className: "suspend-confirm-modal",
      okText: "cancel subscription",
      onOk: (id?: any) => {
        onCancel();
      },
    }
  );

  const { setShow: setLegacyRenew, component: legacyRenewComp } = useConfirm(
    <div className="flex column start align-start">
      <p>
        Your current sponsorship is scheduled to expire on{" "}
        {sponsor?.graceExpireDate}. You can:
      </p>
      <ul>
        <li>
          Renew on an annual basis. In this case, you'll be charged $110{" "}
          {sponsor?.scheduleChargeDate}, and your next renewal date will be{" "}
          {sponsor?.renewAnnualNextBillingDate} (or one year plus{" "}
          {sponsor?.renewPlusDays} days from now). Your card will be charged on
          the same day each subsequent year until you cancel this plan.
        </li>
        <li>
          Renew on a monthly basis. In this case, you'll be charged $12{" "}
          {sponsor?.scheduleChargeDate}, and your next renewal date will be{" "}
          {sponsor?.renewMonthNextBillingDate} (or one month plus{" "}
          {sponsor?.renewPlusDays} days from now). Your card will be charged on
          the same day each subsequent month until you cancel this plan.
        </li>
      </ul>
      <p>Please click proceed to select a plan</p>
    </div>,
    {
      title: "Membership Renewal",
      className: "suspend-confirm-modal",
      okText: "proceed",
      onOk: (id?: any) => {
        // go to payment page
        history.push({
          pathname: "/dashboard/membership/fiscal/summary/renew",
          state: {
            renewAnnualNextBillingDate: sponsor.renewAnnualNextBillingDate,
            renewMonthNextBillingDate: sponsor.renewMonthNextBillingDate,
            expireDate: sponsor.expireDate,
          },
        });
      },
    }
  );

  const onCancel = () => {
    cancelSubscription().then((data) => onLoad());
  };

  const couponValidate = (code: string) => {
    if (!code) {
      setChangeCoupon({ code, discount: 0, error: false });
      return false;
    }
    couponCheck({
      name: code,
      productType: "fiscal_sponsorship",
      price: +sponsor?.changePlan?.amount,
    })
      .then((data) =>
        setChangeCoupon({ code, discount: data?.discount, error: false })
      )
      .catch((e) => {
        setChangeCoupon({
          code: "",
          discount: 0,
          error: true,
        });
      });
  };
  const [changeShow, setChangeShow] = useState(false);
  const [changeCoupon, setChangeCoupon] = useState({
    code: "",
    discount: 0,
    error: false,
  });
  const { setShow: handleShowPlan, component: planComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>
        You are changing your subscription to{" "}
        <b>{sponsor?.changePlan?.title} plan</b> billing.
      </p>
      <p>
        Your current <b>{sponsor?.currentPlan?.title} plan</b> is paid through{" "}
        {sponsor?.graceExpireDate}.
      </p>
      <p>
        If you switch to <b>{sponsor?.changePlan?.title} plan</b> billing,
        you'll be charged ${sponsor?.changePlan?.amount}{" "}
        <b>( minus any applicable credits )</b> {sponsor?.scheduleChargeDate},
        and your next renewal date will be{" "}
        {sponsor?.changePlan?.title === "Annual"
          ? sponsor?.renewAnnualNextBillingDate
          : sponsor?.renewMonthNextBillingDate}{" "}
        (or one {sponsor?.changePlan?.unit} plus {sponsor?.renewPlusDays} days
        from now). Your card will be charged on the same day each subsequent{" "}
        {sponsor?.changePlan?.unit} until you cancel this plan.
      </p>
    </div>,
    {
      title: "Are you sure you want to change ? ",
      className: "suspend-confirm-modal",
      okText: "change",
      onOk: (id?: any) => {
        setChangeShow(true);
      },
    }
  );

  //table
  const columns: any[] = [
    {
      title: "Product type",
      dataIndex: "productType",
      fixed: "left",
    },
    {
      title: "Transaction type",
      dataIndex: "donationType",
      width: 170,
      fixed: isMobile ? undefined : "left",
    },
    {
      title: "Frequency",
      width: 120,
      dataIndex: "frequency",
      fixed: isMobile ? undefined : "left",
      render: (frequency: string) => {
        switch (frequency) {
          case "month":
            return "Recurring - Monthly";
          case "year":
            return "Recurring - Anually";
          case "once":
          default:
            return "One time";
        }
      },
    },
    {
      title: "Receiver name",
      dataIndex: "receiverName",
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 100,
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Status",
      width: 90,
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: string, _: any) => (
        <Tag color={statusTypes[text] ? statusTypes[text]?.color : "gray"}>
          {statusTypes[text] ? statusTypes[text]?.label : text}
        </Tag>
      ),
    },
    {
      title: "payment method",
      dataIndex: "paymentMethod",
      width: 160,
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: "Coupon code",
      dataIndex: "coupon",
      width: 130,
    },
    {
      title: "Invoice number",
      dataIndex: "orderId",
      width: 150,
    },
    {
      title: "Date created",
      dataIndex: "createDate",
      width: 130,
      render: (text: string, row: any) =>
        (text && dayjs(text).format(dateFormat)) || "N/A",
    },
    {
      title: "",
      width: 80,
      fixed: isMobile ? undefined : "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleViewOrder(row.id)}>
                  View order
                </Menu.Item>
                <Menu.Item onClick={() => history.push("/dashboard/message")}>
                  Message Admin
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    pageSize: 10,
    keyword: "",
    status: Object.keys(statusTypes).filter((_) => _ !== "failed"),
  });
  const [tableData, setTableData] = useState([]);

  const getList = () => {
    const { pageSize: limit, ...others } = params;
    let _data = {
      ...others,
      limit,
    };
    myTransactions(_data).then((data) => {
      setTableData(data.items);
    });
  };
  useEffect(() => {
    show === false && getList();
    // eslint-disable-next-line
  }, [params, show]);

  const { id: userId } = localStorageGet("info");
  //handler
  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${
        process.env.REACT_APP_API_HOST
      }/contribution/transactions/export-csv?${queryString.stringify({
        ...others,
        userId,
        tz: dayjs.tz.guess(),
      })}`,
      "_blank"
    );
  };
  const handleViewOrder = (id: any) => {
    history.push(`/dashboard/transaction/${id}`);
  };

  return (
    <OverviewContainer className="overview-membership-container common-container">
      <h1>Overview</h1>
      <div className={`flex align-start ${isMobile && "column"}`}>
        <div className="card-wrap left-item">
          <h3>Current plan</h3>
          <h2>Fiscal sponsorship</h2>
          <p className="flex start align-start">
            <CheckOutlined />
            <span>
              Eligibility for grants, crowdfunding, selling tickets, corporate
              matching gifts, and other funding opportunities.
            </span>
          </p>
          <p className="flex start align-start">
            <CheckOutlined />
            <span>Tax-deductible donations of money and goods.</span>
          </p>
          <p className="flex start align-start">
            <CheckOutlined />
            <span>
              Free review and feedback on grant applications and fundraising
              materials.
            </span>
          </p>
          <p className="flex start align-start">
            <CheckOutlined />
            <span>All the basic Member benefits of <TheField/>.</span>
          </p>
          <Divider />
          <p className="flex">
            <span>Price</span>{" "}
            {sponsor?.currentPlan?.amount
              ? "$ " +
                sponsor?.currentPlan?.amount +
                " " +
                sponsor?.currentPlan?.unit
              : "N/A"}
          </p>
          <p className="flex">
            <span>Billing cycle</span>
            {sponsor?.currentPlan?.title || "N/A"}
          </p>
          <p className="flex">
            <span>Expiration Date</span>
            {sponsor?.graceExpireDate || "N/A"}
          </p>
          {selectOption?.title !== "Once" && (
            <p className="flex">
              <span>Next billing date</span>
              {(sponsor?.currentPlan?.nextBillingDate &&
                dayjs(sponsor?.currentPlan?.nextBillingDate).format(
                  dateFormat
                )) ||
                "N/A"}
            </p>
          )}
          <Divider />
          {/*<Link to="/">learn more about your subscription</Link>*/}
          {sponsor?.legacyRenew &&
            (sponsor?.renewing ? (
              <Tooltip
                overlay="Your renewal request is in process."
                placement="topLeft"
              >
                <span className="cancel-span disabled">renew plan</span>
              </Tooltip>
            ) : (
              <span
                className="cancel-span"
                onClick={() => setLegacyRenew(true)}
              >
                renew plan
              </span>
            ))}
          <span
            className="cancel-span"
            onClick={() => {
              if (sponsor?.changePlan?.priceId) {
                handleShowPlan(true);
              } else if (sponsor?.renewing) {
                message.warn("Your renewal request is in process.");
              } else {
                message.warn("Please try RENEW_PLAN or CANCEL_MEMBERSHIP");
              }
            }}
          >
            change plan
          </span>
          <span className="cancel-span" onClick={() => setPopup(true)}>
            CANCEL MEMBERSHIP
          </span>
          <p className="tips">
            When you cancel your Fiscal Sponsorship plan, it remains active
            until the end of the annual or monthly subscription period. Once
            expired, your account will be switched to our Free plan.
          </p>
        </div>
        <div className="card-wrap right-item">
          <h3>Payment information</h3>
          {bill?.type !== "paypal" && (
            <>
              <h4>Card information</h4>
              <div className="field-form-items">
                <span>CARD NUMBER</span>
                <Input
                  size="large"
                  disabled
                  value={
                    bill?.cardInformation?.last4
                      ? "**** **** **** " + bill?.cardInformation?.last4
                      : "N/A"
                  }
                />
              </div>
              <Divider />
            </>
          )}

          <h4>Billing information</h4>
          <Form layout="vertical" size="large" name="basic" form={form}>
            <Form.Item label="Full Name" name="name">
              <Input disabled placeholder="Enter full name" />
            </Form.Item>
            <Form.Item label="email address" name="email">
              <Input disabled placeholder="Email" />
            </Form.Item>
            <Form.Item label="billing address" name="address">
              <Input disabled placeholder="Address" />
            </Form.Item>
            <Form.Item label="City" name="city">
              <Input disabled placeholder="City" />
            </Form.Item>
            <Form.Item label="Country" name="country">
              <Input disabled placeholder="Country" />
            </Form.Item>
            <div className="flex">
              <Form.Item label="State" name="state">
                <Input disabled placeholder="State" />
              </Form.Item>
              <Form.Item label="Zip Code" name="zipCode">
                <Input disabled placeholder="Zip Code" />
              </Form.Item>
            </div>
            <Button
              type="primary"
              block
              onClick={() =>
                history.push(
                  `/dashboard/membership/overview/change/${sponsor?.currentPlan?.planId}/${sponsor?.currentPlan?.priceId}`
                )
              }
            >
              EDIT Payment info
            </Button>
          </Form>
        </div>
      </div>
      <Divider />
      <div className="table-wrap">
        <Search
          filter={false}
          value={params}
          onChange={(e) => {
            setParams({
              ...params,
              ...e,
            });
          }}
          action={["export"]}
          onExport={handleExport}
        />
        <div className="table-item">
          <InvoiceTable
            title="Transaction"
            tableData={tableData}
            columns={columns}
            onSearch={(e) =>
              setParams({
                ...params,
                keyword: e,
              })
            }
            noSticky
            scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
            pagination={{
              current: params.page,
              pageSize: params.pageSize,
              onChange: (page: number, pageSize: number) => {
                setParams({
                  ...params,
                  ...{
                    page,
                    pageSize,
                  },
                });
              },
            }}
          />
        </div>
      </div>
      <Modal
        visible={!!show}
        width={420}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            {getPopupButtonByMembershipStatus(sponsor?.status)}
          </Button>,
        ]}
      >
        <h2>
          {getPopupTitleByMembershipStatus(
            sponsor?.status,
            "Apply for your fiscal sponsorship"
          )}
        </h2>
        <p>
          {getPopupDescByMembershipStatus(
            sponsor?.status,
            "Fund your artistic projects with tax-deductible donations and grants – and leave the paperwork to us."
          )}
        </p>
      </Modal>
      <AntModal
        closable
        width={570}
        visible={changeShow}
        maskClosable={false}
        className="change-plan-pay-modal"
        okText="CONFIRM PAYMENT"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          style: { width: "calc(100% - 32px)", flex: 1, margin: "10px 16px" },
        }}
        onCancel={(e) => setChangeShow(false)}
        onOk={(e) => {
          subscribeStripeChange({
            priceId: sponsor?.changePlan?.priceId,
            coupon: changeCoupon.code,
          }).then(() => {
            setChangeShow(false);
            getStatus();
            getList();
          });
        }}
      >
        <h1>Are you sure you want to change plan?</h1>
        <div
          className="card-wrap"
          style={{ fontSize: 16, color: "#797F84", padding: "0 16px" }}
        >
          <Divider />
          <h3>Order summary</h3>
          <p className="flex">
            <span>Plan</span>
            Fiscal sponsorship
          </p>
          <p className="flex">
            <span>Price</span>
            <i>
              ${" "}
              <b style={{ fontSize: 16 }}>
                {sponsor?.changePlan?.amount || "--"}
              </b>
            </i>
          </p>
          <p className="flex">
            <span>Billing cycle</span>
            {sponsor?.changePlan?.title || "--"}
          </p>
          {sponsor?.changePlan?.title !== "Once" && (
            <p className="flex">
              <span>Next billing date</span>
              {dayjs(
                sponsor?.changePlan?.title === "Annual"
                  ? sponsor?.renewAnnualNextBillingDate
                  : sponsor?.renewMonthNextBillingDate
              ).format("MMMM DD, YYYY")}
            </p>
          )}
          <p className="flex">
            <span>Promo code:{changeCoupon.code}</span>
            {Number(changeCoupon?.discount || 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}
          </p>
          <Divider />
          <h3 className="flex">
            Total amount
            <b>
              {Number(
                sponsor?.changePlan?.amount - changeCoupon?.discount >= 0
                  ? sponsor?.changePlan?.amount - changeCoupon?.discount
                  : 0
              ).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </b>
          </h3>
          <Divider />
          <div className="flex align-start" style={{ width: "100%" }}>
            <div style={{ flex: "0 0 calc(100% - 120px)" }}>
              <div
                className={`field-form-items ${
                  changeCoupon?.error ? "field-error-item" : ""
                }`}
                style={{ width: "100%" }}
              >
                <span>PROMO CODE</span>
                <Input
                  allowClear
                  placeholder="Promo code..."
                  onChange={(e) =>
                    setChangeCoupon({
                      ...changeCoupon,
                      code: e?.target.value,
                      error: false,
                    })
                  }
                />
              </div>
              <p className="error-text">Invalid code, please try another one</p>
            </div>
            <Button
              style={{ flex: "0 0 100px", height: "56px", marginLeft: 20 }}
              onClick={() => couponValidate(changeCoupon.code)}
            >
              Apply
            </Button>
          </div>
          <Divider />
          <p className="tips">
            By confirming this payment, you agree to our{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://thefield.org/terms-of-service/"
            >
              TERMS OF SERVICE
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.thefield.org/wp-content/uploads/2024/04/The-Field-Fiscal-Sponsorship-Artist-Agreement-Blank.pdf"
            >
              SPONSORED ARTIST PROGRAM AGREEMENT.
            </a>{" "}
            To ensurea continued service, we'll store and update (e.g., upon
            expiration) your payment method.
          </p>
        </div>
      </AntModal>
      {component}
      {planComponent}
      {legacyRenewComp}
    </OverviewContainer>
  );
};

export default Overview;
