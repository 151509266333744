import { RouteProps } from "react-router-dom";
import MyMsg from "@/pages/dashboard/myMsg/myMsg";
import { AdminUsers } from "./pages/admin_user/admin_users";
import { AdminArtists } from "./pages/admin_artists/admin_artists";
import { AdminDonors } from "./pages/admin_donors/admin_donors";
import { GrantList } from "./pages/admin_grant/grant_list";
import { GrantPost } from "./pages/admin_grant/grant_post";
import { GrantEdit } from "./pages/admin_grant/grant_edit";
import { ApplicationList } from "./pages/admin_grant/application_list";
import { ApplicationReview } from "./pages/admin_grant/application_review";
import { GrantApplications } from "./pages/admin_grant/grant_applications";

import ApplicationMembership from "./pages/admin_application/membership/list";
import { SponsorshipReview } from "./pages/admin_application/membership/sponsorship_review";

// import FinanceContribution from "./pages/admin_finance/contribution/list_new";
import FinanceContribution from "./pages/admin_finance/contribution/list";
import FinanceContributionOrder from "./pages/admin_finance/contribution/detail";
import FinanceContributionOrderNote from "./pages/admin_finance/contribution/note";

import FinanceOrder from "./pages/admin_finance/order/list";
import FinanceOrderDetail from "./pages/admin_finance/order/detail";
import FinanceInkind from "./pages/admin_finance/inkind/list";

import ProgramCoupon from "./pages/admin_program/coupons";

import Auth from "./pages/admin_auth";

import { Documents } from "./pages/admin_documents/documents";

import { EventList } from "./pages/admin_activity/artist_event/event_list";
import { EventFieldList } from "./pages/admin_activity/field_event/list";
import EventCreate from "./pages/admin_activity/field_event/create";
import { EventRegistration } from "./pages/admin_activity/field_event/registation";

import { CrowdList } from "@/adminApp/pages/admin_activity/crowd_campaign";
import { DonorReport } from "./pages/admin_donors/donor_report";
import InKindDetail from "@/pages/dashboard/donation/InKindDetail";
import FieldCrowdList from '@/pages/dashboard/donation';
import FieldCrowdCreate from '@/pages/dashboard/donation/Create';
import FieldCrowdDonations from '@/pages/dashboard/donation/CampaignDetail';
import { AdminChgPwd } from "./pages/admin_chg_pwd";

export const admin_router_sign_in = "/sign/in";
export const admin_router_sign_in_2fa = "/sign/2fa";
export const admin_router_change_pwd = "/change-password";
export const admin_router_sign_forget_pwd = "/sign/password";
export const admin_router_sign_reset_pwd = "/sign/password/change/:id/:salt/:from(donor)?";
export const admin_router_users = "/users";
export const admin_router_artists = "/artists";
export const admin_router_donors = "/donors";
export const admin_router_donors_report = "/donors/report";
export const admin_router_grant_list = "/application/grants-list";
export const admin_router_application_list = "/application/application-list";
export const admin_router_applications_of = "/application-list/:id";
export const admin_router_grant_post = "/grant-post";
export const admin_router_grant_edit = "/grant-edit/:id";
export const admin_router_grant_view = "/grant/:id";
export const admin_router_application_review = "/grant-review/:id";

export const admin_router_application_membership = "/application/membership";
export const admin_router_application_membership_review =
  "/application/membership/:id";

export const admin_router_finance_contribution = "/finance/contribution";
export const admin_router_finance_contribution_order =
  "/finance/contribution/:id/:edit(edit)?";
export const admin_router_finance_contribution_order_note =
  "/finance/contribution/:id/note";

export const admin_router_finance_order = "/finance/order";
export const admin_router_finance_inkind = "/finance/inkind";
export const admin_router_finance_inkind_detail = "/finance/inkind/:id";
export const admin_router_finance_order_detail =
  "/finance/order/:id/:edit(edit)?";
export const admin_router_finance_order_detail_note = "/finance/order/:id/note";

export const admin_router_program_coupon = "/program/coupon";

export const admin_router_events = "/activity/events";
export const admin_router_event_crowd = "/activity/crowd";
export const admin_router_event_field = "/program/field";
export const admin_router_crowd_field = "/program/crowd";
export const admin_router_crowd_field_preview = "/program/crowd/preview/:id";
export const admin_router_crowd_field_donation_detail = "/program/crowd/donation/:id";
export const admin_router_crowd_field_edit = "/program/crowd/create/:id?";
export const admin_router_event_field_create = "/program/field/:id(\\d+)?";
export const admin_router_event_field_review =
  "/program/field/:id(\\d+)/review";

export const admin_router_event_field_registration =
  "/program/field/:id(\\d+)/:registration(registration\\-.*)";

export const admin_router_message = "/message";
export const admin_router_message_to = "/message/:to";
export const admin_router_documents = "/documents";
export const admin_router_home = "/";

export const home_routers: (RouteProps & { key?: string })[] = [
  {
    path: admin_router_users,
    component: AdminUsers,
    exact: true,
  },
  {
    path: admin_router_artists,
    component: AdminArtists,
    exact: true,
  },
  {
    path: admin_router_donors,
    component: AdminDonors,
    exact: true,
  },
  {
    path: admin_router_donors_report,
    component: DonorReport,
    exact: true,
  },
  {
    path: admin_router_grant_list,
    component: GrantList,
    exact: true,
  },
  {
    path: admin_router_application_list,
    component: ApplicationList,
    exact: true,
  },
  {
    path: admin_router_applications_of,
    component: GrantApplications,
    exact: true,
  },
  {
    path: admin_router_grant_post,
    component: GrantPost,
    exact: true,
  },
  {
    path: admin_router_grant_edit,
    component: GrantEdit,
    exact: true,
  },
  {
    path: admin_router_application_review,
    component: ApplicationReview,
    exact: true,
  },
  {
    path: admin_router_application_membership,
    component: ApplicationMembership,
    exact: true,
  },
  {
    path: admin_router_application_membership_review,
    component: SponsorshipReview,
    exact: true,
  },
  {
    path: admin_router_finance_contribution,
    component: FinanceContribution,
    exact: true,
  },
  {
    path: admin_router_finance_contribution_order,
    component: FinanceContributionOrder,
    exact: true,
  },
  {
    path: admin_router_finance_contribution_order_note,
    component: FinanceContributionOrderNote,
    exact: true,
  },
  {
    path: admin_router_finance_order,
    component: FinanceOrder,
    exact: true,
  },
  {
    path: admin_router_finance_inkind,
    component: FinanceInkind,
    exact: true,
  },
  {
    path: admin_router_finance_inkind_detail,
    component: InKindDetail,
    exact: true,
  },
  {
    path: admin_router_finance_order_detail,
    component: FinanceOrderDetail,
    exact: true,
  },
  {
    path: admin_router_finance_order_detail_note,
    component: FinanceContributionOrderNote,
    exact: true,
  },
  {
    path: admin_router_program_coupon,
    component: ProgramCoupon,
    exact: true,
  },
  {
    path: admin_router_message,
    component: MyMsg,
    key: "msg-main",
    exact: true,
  },
  {
    path: admin_router_message_to,
    component: MyMsg,
    exact: true,
  },
  {
    path: admin_router_events,
    component: EventList,
    exact: true,
  },
  {
    path: admin_router_documents,
    component: Documents,
    exact: true,
  },
  //admin_router_event_crowd
  {
    path: admin_router_event_crowd,
    component: CrowdList,
    exact: true,
  },
  {
    path: admin_router_event_field,
    component: EventFieldList,
    exact: true,
  },

  {
    path: admin_router_event_field_create,
    component: EventCreate,
    exact: true,
  },
  {
    path: admin_router_event_field_registration,
    component: EventRegistration,
    exact: true,
  },
  {
    path: admin_router_crowd_field,
    component: FieldCrowdList,
    exact: true,
  },
  {
    path: admin_router_crowd_field_donation_detail,
    component: FieldCrowdDonations,
    exact: true,
  },
  {
    path: [admin_router_crowd_field_edit, admin_router_crowd_field_edit.split(':')[0]],
    component: FieldCrowdCreate,
    exact: true,
  },
  {
    path: admin_router_change_pwd,
    component: AdminChgPwd,
    exact: true,
  },
  {
    path: "/auth/:token([\\w\\.\\-]+)",
    component: Auth,
    exact: true,
  },
];
